import { FC, SVGProps } from 'react'
import theme from 'theme'

type Props = SVGProps<SVGSVGElement>

const ChevronRight: FC<Props> = ({ ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M9.70498 6L8.29498 7.41L12.875 12L8.29498 16.59L9.70498 18L15.705 12L9.70498 6Z"
      fill={theme.baseColors.greyscale[80]}
    />
  </svg>
)

export default ChevronRight
