import { Box, ToggleButtonGroup } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Container = styled(Box)`
  width: 100%;

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
    max-height: 50%;
    display: block;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: ${theme.colors.borderLow};
    margin-top: 100px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }
`

export const EmptySSMGridContainer = styled(Box)<{ opacity: number }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ opacity }) => `${opacity === 0 ? '0px !important' : `${theme.spacing.small}px !important`}`};
  transition: height 0.2s ease-in-out;
  height: ${({ opacity }) => `${opacity === 1 ? '40px' : '0'}`};
  overflow: hidden;

  .empty-message-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
    background: ${theme.baseColors.info[10]};
    border-radius: ${theme.attributes.borderRadius.small};
    height: 40px;

    .empty-message-inner {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.sm}px;
      flex-grow: 1;
      width: 100%;

      .empty-message-text {
        color: ${theme.colors.white};
        text-align: center;
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.normal};
        line-height: normal;
      }
    }
  }
`

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.medium}px ${theme.spacing.small}px ${theme.spacing.medium}px ${theme.spacing.medium}px;
  height: 100px;

  .header-title {
    color: ${theme.colors.white};
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.xxxLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.bold};
    line-height: normal;
  }

  .header-right-corner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${theme.spacing.small}px;
    min-width: 202px;

    .total-products-text {
      color: ${theme.colors.white};
      text-align: center;
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
      white-space: nowrap;
    }

    .MuiDivider-root {
      background: ${theme.colors.borderLow};
    }

    .MuiIconButton-root {
      width: 40px;
      height: 40px;
      color: ${theme.colors.textPrimary};
    }
  }
`

export const ViewToggle = styled(ToggleButtonGroup)`
  height: 40px;

  .MuiToggleButton-root {
    height: 40px;
    padding: 0 ${theme.spacing.medium}px;
    border: 1px solid ${theme.colors.controlBorder};
    color: ${theme.colors.textPrimary};
    font-family: ${theme.typography.fontFamily.primary};
    text-align: center;
    font-size: ${theme.typography.fontSize.xLarge};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thin};
    line-height: normal;
    text-transform: none;
    width: 164px;
    white-space: nowrap;

    &:first-of-type {
      border-radius: ${theme.attributes.borderRadius.large} 0 0 ${theme.attributes.borderRadius.large};

      &.Mui-selected {
        color: ${theme.colors.white};
        background: ${theme.baseColors.primary[20]};

        &:hover {
          background: ${theme.baseColors.primary[20]};
        }
      }
    }

    &:last-of-type {
      border-radius: 0 ${theme.attributes.borderRadius.large} ${theme.attributes.borderRadius.large} 0;

      &:hover {
        border-left: 1px solid ${theme.colors.controlBorder};
      }

      &.Mui-selected {
        border-left: 1px solid ${theme.colors.controlBorder};
        color: ${theme.colors.white};
        background: ${theme.baseColors.primary[20]};

        &:hover {
          background: ${theme.baseColors.primary[20]};
          border-left: 1px solid ${theme.colors.controlBorder};
        }
      }
    }

    &.Mui-selected {
      color: ${theme.colors.white};
      background: ${theme.baseColors.primary[20]};
    }
  }
`

export const ContentContainer = styled(Box)`
  width: 100%;
  padding: ${theme.spacing.medium}px;
  overflow-y: auto;
  height: calc(100vh - 100px);

  ::-webkit-scrollbar {
    width: 4px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: none;
    background: ${theme.colors.borderLow};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.textPrimary};
    border-radius: ${theme.attributes.borderRadius.quaternary};
  }
`

export const SSMGrid = styled(Box)`
  display: grid;
  grid-template-columns: 77px 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 45px 1fr 1fr 1fr 1fr 1fr 1fr;
`

export const GridHeader = styled(Box)`
  display: flex;
  padding: ${theme.spacing.xSmall}px ${theme.spacing.small}px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.borderLow};

  .MuiTypography-root {
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    line-height: normal;
    font-style: normal;

    &.name {
      color: ${theme.colors.white};
      font-weight: ${theme.typography.fontWeight.thick};
    }

    &.value {
      color: ${theme.colors.textSecondaryLow};
      font-weight: ${theme.typography.fontWeight.normal};
    }
  }
`

export const BasicCell = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${theme.colors.borderLow};
  gap: ${theme.spacing.xSmall}px;

  .nist-pillar-text {
    color: ${theme.colors.white};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.thick};
    line-height: normal;
  }

  .nist-value-text {
    color: ${theme.colors.textSecondaryLow};
    text-align: center;
    font-family: ${theme.typography.fontFamily.primary};
    font-size: ${theme.typography.fontSize.large};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.normal};
    line-height: normal;
  }
`
