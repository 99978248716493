import { FC, useState, useRef } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'

/* Utils */
import { CategoryActivityItem, PerformanceStatus } from '../../../../../../models'

/* Components */
import { CategoryWrapper } from './category.styles'
import CategoryChart from './CategoryChart'
import DataSources from './DataSources'
import AvailableCategory from './AvailableCategory'
import EmptyDataCategory from './EmptyDataCategory'
import { OverPerformIcon } from '../../../../../components/svg'

interface Props {
  category: CategoryActivityItem
  handleCategoryClick: (category: string) => void
  handleCpiRedirect: (category: CategoryActivityItem) => void
  hoveredCard: string
  minWidth?: string
}

const Category: FC<Props> = ({ category, handleCategoryClick, handleCpiRedirect, hoveredCard, minWidth }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)
  const dataSources = category.status === PerformanceStatus.active ? category.dataSources : []
  const thirdBreakpoint = useMediaQuery('(min-width:1440px)')
  const margin = { top: 20, right: 0, bottom: 40, left: 0 }
  const [width, setWidth] = useState(thirdBreakpoint ? 215 : 186)
  const height = thirdBreakpoint ? 124 - margin.top - margin.bottom : 116 - margin.top - margin.bottom

  if (category.status === PerformanceStatus.active && !category.data?.length) {
    return (
      <EmptyDataCategory
        category={category}
        handleCategoryClick={handleCategoryClick}
        dataSources={dataSources}
        ishovered={hoveredCard === category.name ? 'true' : 'false'}
        minWidth={minWidth}
      />
    )
  }
  if (category.status !== PerformanceStatus.active) {
    return (
      <AvailableCategory
        category={category}
        handleCategoryClick={handleCategoryClick}
        handleCpiRedirect={handleCpiRedirect}
        ishovered={hoveredCard === category.name ? 'true' : 'false'}
        minWidth={minWidth}
      />
    )
  }

  const lastValue = category.data.length > 0 ? category.data[category.data.length - 1].yValue : 0
  const remainder = +(lastValue - 100)

  return (
    <CategoryWrapper
      ref={containerRef}
      titlelength={category.name.length}
      ishovered={hoveredCard === category.name ? 'true' : 'false'}
      sx={{ minWidth }}
    >
      <Box className="header" onClick={() => handleCategoryClick(category.name)}>
        <Typography className={`${remainder > 0 ? '' : 'category-margin'} category-title`}>{category.name}</Typography>

        {remainder > 0 && (
          <Box className="over-perform">
            <OverPerformIcon />
            <Typography className="value">
              <b>{remainder}%</b> Over Performing
            </Typography>
          </Box>
        )}
      </Box>

      <CategoryChart
        category={category}
        ref={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          containerRef,
          svgRef
        }}
        width={width}
        height={height}
        margin={margin}
        setWidth={setWidth}
      />
      <DataSources dataSources={dataSources} />
    </CategoryWrapper>
  )
}

export default Category
