import { Box } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const ChartHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing.small}px;
  flex: 0 1 auto;

  .info-block {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.medium}px;

    > div {
      display: flex;
      align-items: center;
      gap: ${theme.spacing.xSmall}px;
    }

    .square {
      width: 16px;
      height: 16px;
      border-radius: ${theme.attributes.borderRadius.small};
    }

    .blue {
      background: ${theme.baseColors.info[10]};
    }

    .orange {
      background: ${theme.baseColors.info[40]};
    }

    .name {
      color: ${theme.colors.white};
      font-size: ${theme.typography.fontSize.xLarge};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: normal;
    }
  }

  .filter-icon-block {
    position: relative;
    display: flex;
    border-radius: ${theme.attributes.borderRadius.circle};
    background: transparent;

    .active-circle {
      position: absolute;
      top: -3px;
      right: -5px;
      background: ${theme.colors.primaryDark};
      border-radius: ${theme.attributes.borderRadius.circle};
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;

      .circle {
        width: 8px;
        height: 8px;
        background: #ffe082;
        border-radius: ${theme.attributes.borderRadius.circle};
      }
    }
  }
`

export const ChartWrapper = styled(Box)`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  height: 100%;

  .description-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: ${theme.attributes.borderRadius.quaternary} ${theme.attributes.borderRadius.quaternary} 0 0;
    background: ${theme.colors.primaryDarker};
    border: 1px solid rgba(147, 143, 153, 0.16);
    box-shadow: ${theme.attributes.boxShadow.feedSection};
    height: 150px;

    @media (min-height: 801px) {
      gap: ${theme.spacing.sm}px;
    }

    @media (max-width: 1440px) and (max-height: 900px) {
      gap: ${theme.spacing.sm}px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-width: 1440px) and (min-height: 901px) {
      gap: ${theme.spacing.sm}px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-width: 1366px) and (max-height: 768px) {
      gap: ${theme.spacing.sm}px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-width: 1280px) {
      gap: ${theme.spacing.sm}px;

      .chips-container {
        margin-top: 0;
      }
    }

    @media (max-height: 800px) {
      gap: ${theme.spacing.sm}px;
      height: 119px;
    }

    .box-wrapper {
      border-radius: ${theme.attributes.borderRadius.tertiary};
      background: #281e28;
      padding: ${theme.spacing.small}px;

      @media (max-height: 800px) {
        padding: ${theme.spacing.sm}px 0 0 0;
      }

      .measurement-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        gap: ${theme.spacing.small}px;
        padding: 0 ${theme.spacing.small}px;

        .status {
          display: flex;
          align-items: flex-end;

          span {
            font-family: ${theme.typography.fontFamily.primary};
            font-size: ${theme.typography.fontSize.medium};
            font-style: normal;
            font-weight: ${theme.typography.fontWeight.normal};
            line-height: 16px;
            letter-spacing: 0.4px;
          }
        }

        .column {
          flex-direction: column;
        }
      }

      .box-wrapper-options {
        display: flex;
        gap: ${theme.spacing.md}px;

        @media (max-width: 1400px) {
          flex-wrap: wrap;
        }
      }

      .label-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        text-transform: none;

        > div {
          cursor: pointer;
        }

        .title {
          margin-bottom: ${theme.spacing.xSmall}px;
        }

        .count {
          border-radius: ${theme.attributes.borderRadius.large};
          border: 1px solid ${theme.colors.controlBorder};
          background: #ffe082;
          position: absolute;
          top: 30px;
          right: 5px;
          min-width: 14px;
          height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #66006e;
          text-align: center;
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.mediumSmall};
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: 0.5px;
        }
      }
    }

    .wrapper-options {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      max-width: 679px;
      padding-left: ${theme.spacing.small}px;
      padding-right: ${theme.spacing.small}px;
      padding-bottom: ${theme.spacing.small}px;
    }

    .wrapper-options,
    .legend-wrapper,
    .wrapper-measurement {
      width: 100%;
    }

    .wrapper-measurement {
      @media (max-width: 1280px) {
        max-width: 266px;
      }

      @media (max-width: 1440px) {
        max-width: 312px;
      }

      @media (min-width: 1441px) {
        max-width: 422px;
      }
    }

    .px-7 {
      padding-left: 7px;
      padding-right: 7px;
    }

    .legend-wrapper {
      padding: ${theme.spacing.small}px;
      max-width: 363px;

      @media (max-width: 1400px) {
        max-width: 187px;
        width: auto;
      }

      @media (max-width: 1280px) {
        max-width: 187px;
      }

      @media (max-height: 800px) {
        padding-top: ${theme.spacing.sm}px;
        padding-bottom: unset;

        .legend-key {
          margin-right: 13px;
        }

        .legend-container {
          gap: 3px !important;
        }
      }

      .legend {
        @media (max-width: 1400px) {
          display: flex;
          flex-direction: row;
          flex-flow: nowrap;
          gap: ${theme.spacing.md}px;
          align-items: start;
          justify-content: center;
        }
      }

      @media screen and (max-width: 1440px) and (max-height: 800px) {
        max-width: 279px;

        .legend {
          flex-flow: column wrap;

          .legend-container {
            flex-flow: row wrap;
            gap: ${theme.spacing.xSmall}px ${theme.spacing.small}px !important;
          }
        }
      }
    }

    .text {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.large};
      line-height: 20px;
      letter-spacing: 0.1px;
      color: ${theme.colors.white};

      .severity {
        text-transform: capitalize;
      }
    }

    .status {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: 76.533px;
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.normal};
      line-height: 57.439px;
      color: ${theme.baseColors.info[20]};
      margin-top: 0;
      text-transform: capitalize;
    }

    .updated-at {
      font-family: ${theme.typography.fontFamily.primary};
      font-weight: ${theme.typography.fontWeight.bold};
      font-size: ${theme.typography.fontSize.mediumSmall};
      line-height: 16px;
      letter-spacing: 0.5px;
      color: ${theme.baseColors.greyscale[80]};
    }

    .legend {
      width: auto;
      display: flex;
      flex-flow: column wrap;

      .legend-container {
        display: flex;
        flex-wrap: wrap;
        gap: 14px;

        @media (max-width: 1500px) {
          gap: ${theme.spacing.xSmall}px;
        }
      }

      .legend-key {
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.bold};
        font-size: ${theme.typography.fontSize.large};
        line-height: 20px;
        letter-spacing: 0.1px;
        color: ${theme.colors.white};
        margin-bottom: ${theme.spacing.xSmall}px;
      }

      .legend-item {
        display: flex;
        align-items: center;

        .MuiTypography-root {
          margin-left: ${theme.spacing.xSmall}px;
          font-family: ${theme.typography.fontFamily.primary};
          font-weight: ${theme.typography.fontWeight.bold};
          font-size: ${theme.typography.fontSize.medium};
          line-height: 16px;
          letter-spacing: 0.5px;
          color: ${theme.colors.white};
          white-space: nowrap;
        }
      }
    }

    .data-source-wrapper {
      display: flex;
      flex-flow: column wrap;

      .data-source-select-wrapper {
        max-width: 120px;
        margin-top: ${theme.spacing.half}px;
      }

      .title {
        font-family: ${theme.typography.fontFamily.primary};
        font-weight: ${theme.typography.fontWeight.bold};
        font-size: ${theme.typography.fontSize.large};
        line-height: 20px;
        letter-spacing: 0.1px;
        color: ${theme.colors.white};
        margin-bottom: ${theme.spacing.half}px;
      }
    }
  }

  .chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;

    .chart-wrapper {
      flex: 1 1 auto;
      width: 100%;
      height: calc(100vh - 56px - 24px - 74px - 116px - 100px + 24px);
    }

    .trends-d3-wrapper {
      position: relative;
      width: 100%;
      height: 100%;

      .svg-d3 {
        border-bottom-right-radius: ${theme.attributes.borderRadius.quaternary};
        border-bottom-left-radius: 16px;
        border: 1px solid rgba(147, 143, 153, 0.16);
        background: ${theme.colors.backgroundLight};

        .event-indicator-count {
          cursor: pointer;
        }

        .add-event-button {
          cursor: pointer;
          display: none;

          &:hover {
            .add-button-circle {
              fill: rgba(208, 195, 204, 0.15);
              stroke: ${theme.baseColors.greyscale[80]};
            }
          }
        }

        .add-button-tooltip {
          display: none;
        }

        .count-tooltip {
          display: none;
        }

        .sla {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${theme.spacing.small}px;
          background: rgba(208, 188, 255, 0.16);
          box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
          border: 0 solid ${theme.colors.controlBorder};
          border-radius: ${theme.attributes.borderRadius.quaternary};
          backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
          z-index: 1000000;

          .sla-tooltip-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: ${theme.spacing.small}px;
            background: rgba(208, 188, 255, 0.16);
            box-shadow: ${theme.attributes.boxShadow.selectFieldPaper};
            border: 0 solid ${theme.colors.controlBorder};
            border-radius: ${theme.attributes.borderRadius.quaternary};
            backdrop-filter: blur(6px) brightness(0.8) contrast(0.9) hue-rotate(320deg);
            z-index: 1000000;
          }
        }
      }

      .data-point-tooltip {
        display: none;
        position: absolute;
        opacity: 1;
        padding: ${theme.spacing.small}px;
        gap: ${theme.spacing.sm}px;
        border-radius: ${theme.attributes.borderRadius.secondary};
        border: 1px solid ${theme.colors.controlBorder};
        background: ${theme.colors.borderLow};
        box-shadow: ${theme.attributes.boxShadow.chipCircle};
        color: ${theme.colors.white};
        font-family: ${theme.typography.fontFamily.primary};
        font-size: ${theme.typography.fontSize.xLarge};
        font-style: normal;
        font-weight: ${theme.typography.fontWeight.bold};
        line-height: 24px;
        letter-spacing: 0.1px;

        .asset-wrapper-coverage {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 300px;
          flex-flow: row wrap;

          .asset-wrapper-inner {
            display: flex;
            align-items: center;
            gap: ${theme.spacing.half}px;
            margin-right: 12px;
            width: fit-content;
            max-width: 300px;

            > svg:first-child {
              margin-right: ${theme.spacing.half}px;
              min-width: 12px;
            }
          }

          .tooltip-performance {
            width: fit-content;
            display: flex;
            margin: 4px 0;

            &.subtext {
              &.long {
                width: inherit;
                justify-content: flex-end;
              }
            }
          }

          .long {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }

        .tooltip-performance,
        .tooltip-units {
          color: ${theme.colors.white};
          font-family: ${theme.typography.fontFamily.primary};
          font-size: ${theme.typography.fontSize.large};
          font-style: normal;
          font-weight: ${theme.typography.fontWeight.normal};
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .tooltip-units {
          margin: 0;
        }

        .severity-text {
          text-transform: capitalize;
        }
      }
    }
  }

  .chart-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    background: #2c242c;
  }

  .chart-container::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar-thumb:hover {
    background-color: #524256;
  }

  .chart-container::-webkit-scrollbar:vertical {
    display: none;
  }
`
