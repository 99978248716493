import { FC, SVGProps } from 'react'
import theme from 'theme'

type Props = SVGProps<SVGSVGElement>

const InsightsIcon: FC<Props> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 15V10.625M10 10.625C10.4316 10.625 10.8505 10.5704 11.25 10.4675M10 10.625C9.56838 10.625 9.14953 10.5704 8.75 10.4675M11.875 16.6995C11.2677 16.8147 10.6409 16.875 10 16.875C9.35913 16.875 8.73235 16.8147 8.125 16.6995M11.25 18.685C10.8392 18.728 10.4222 18.75 10 18.75C9.57783 18.75 9.1608 18.728 8.75 18.685M11.875 15V14.8403C11.875 14.0212 12.4236 13.3209 13.132 12.9098C14.9963 11.8281 16.25 9.81048 16.25 7.5C16.25 4.04822 13.4518 1.25 10 1.25C6.54822 1.25 3.75 4.04822 3.75 7.5C3.75 9.81048 5.00371 11.8281 6.86796 12.9098C7.57644 13.3209 8.125 14.0212 8.125 14.8403V15"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default InsightsIcon
