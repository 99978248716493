import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import theme from 'theme'

/* Utils */
import { accountSettingsSelector, setAccountSettings } from '../../../../store/user/userSlice'
import { Tenant, UserRole } from '../../../../models'
import axios from '../../../../lib/axios'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

/* Components */
import AccountInformation from './components/AccountInformation'
import SSOsupport from './components/SSOsupport'
import { Box, CircularProgress, Tab, Typography } from '@mui/material'
import { Container, ContentWrapperMain, EditFixedPanel, MaterialUISwitch, Tabs } from './account-management.styles'
import { useAuth } from '@frontegg/react'
import HeaderComponent from '../../../components/header/HeaderComponent'
import ToolbarComponent from '../../../components/header/ToolbarComponent'
import { breadcrumbs } from '../../../../lib/utils'
import { LibraryDetailsHoverTooltip } from '../../library/library-details/components/LibraryDetailsTooltip'
import ContentWrapper from '../../../components/header/ContentWrapper'
import { PermissionTooltip } from '../../../components/tooltip'

interface IIndustries {
  label: string
  value: string
}

interface TabPanelProps {
  children?: ReactNode
  index: number
  value: number
}

const AccountManagement = () => {
  const dispatch = useAppDispatch()
  const { user: authUser } = useAuth()
  const account = useAppSelector(accountSettingsSelector)
  const [tabValue, setTabValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [roles, setRoles] = useState<UserRole[]>([])
  const [industries, setIndustries] = useState<IIndustries[]>([])
  const [isEdit, setIsEdit] = useState(false)
  const [form, setForm] = useState({} as Tenant)

  useEffect(() => {
    fetchAccountData().catch((e) => e)
    fetchIndustries().catch((e) => e)
    fetchRoles().catch((e) => e)
  }, [])

  const handleChangeTabValue = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  const fetchRoles = async () => {
    try {
      const options = ['Admin', 'CPI Manager', 'Viewer', 'Demo']
      const resp = await axios.get('/api/v1/identity/roles')
      const list = resp.data.data.list.filter((item: UserRole) => options.includes(item.name))
      list[3].name = 'Manager'
      setRoles(list)
    } catch (err) {
      console.error(err)
    }
  }

  const fetchAccountData = async () => {
    try {
      const { data, status } = await axios.get('/api/v3/tenant')
      if (status === 200) {
        dispatch(setAccountSettings(data.data))
        setForm(data.data)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchIndustries = async () => {
    try {
      const { data, status } = await axios.get('/api/v3/tenant/industries')
      if (status === 200) {
        const arr = [] as IIndustries[]
        data.data.forEach((item: string) => {
          arr.push({
            label: item,
            value: item
          })
        })
        setIndustries(arr)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const updateData = (value: string, name: string) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const onSubmit = async (values: Tenant) => {
    setLoading(true)
    try {
      const data = {
        logo: form.logo,
        name: values.name,
        timeZone: form.timeZone,
        industry: form.industry,
        cyFramework: form.cyFramework
      }

      await axios.put('/api/v1/tenant', data)
      localStorage.setItem('framework', JSON.stringify(form.cyFramework))
      setIsEdit(false)
      await fetchAccountData()
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e: any) => {
    if (!['owner', 'admin'].includes(authUser?.roles[0].key || '')) return

    if (e.target.checked) return setIsEdit(e.target.checked)
    setForm(account as Tenant)
    setIsEdit(false)
  }

  return (
    <Container>
      <HeaderComponent breadcrumbs={breadcrumbs.accountManagement}>
        {tabValue === 0 ? (
          <EditFixedPanel
            sx={{
              marginRight: `${theme.spacing.large}px !important`,
              marginBottom: `${theme.spacing.sm}px !important`
            }}
          >
            <Box className="panel-block">
              {!['owner', 'admin'].includes(authUser?.roles[0].key || '') ? (
                <PermissionTooltip
                  PopperProps={{
                    sx: {
                      marginTop: `-${theme.spacing.sm}px !important`
                    }
                  }}
                  title={
                    <>
                      <Box className="tooltip-wrapper-permissions">
                        <Typography className="tooltip-text">Insufficient permissions</Typography>
                      </Box>
                    </>
                  }
                >
                  <MaterialUISwitch
                    sx={{
                      m: 1,
                      cursor: 'default !important',
                      '& .MuiSwitch-switchBase': {
                        cursor: 'default !important',
                        '& + .MuiSwitch-track': {
                          cursor: 'default !important',
                          opacity: '0.12 !important',
                          backgroundColor: '#998d96 !important'
                        }
                      }
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disableTouchRipple
                    onChange={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                  />
                </PermissionTooltip>
              ) : (
                <LibraryDetailsHoverTooltip
                  arrow
                  placement="bottom"
                  key="switch"
                  title={isEdit ? 'Lock to prevent further changes.' : 'Unlock to make changes'}
                >
                  <MaterialUISwitch
                    sx={{ m: 1 }}
                    checked={isEdit}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </LibraryDetailsHoverTooltip>
              )}
            </Box>
          </EditFixedPanel>
        ) : (
          <></>
        )}
      </HeaderComponent>

      <ToolbarComponent align="end">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTabValue}
            aria-label="crowdstrike tabs"
            TabIndicatorProps={{
              style: { display: 'none' }
            }}
          >
            <Tab {...a11yProps(0)} label="Company Information" />
            <Tab {...a11yProps(1)} label="SSO" />
          </Tabs>
        </Box>
      </ToolbarComponent>

      <ContentWrapper line={2}>
        <ContentWrapperMain>
          {loading ? (
            <Box className="loading-wrapper">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TabPanel value={tabValue} index={0}>
                <AccountInformation
                  isEdit={isEdit}
                  form={form}
                  industries={industries}
                  updateData={updateData}
                  onSubmit={onSubmit}
                />
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <SSOsupport roles={roles} />
              </TabPanel>
            </>
          )}
        </ContentWrapperMain>
      </ContentWrapper>
    </Container>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`crowdstrike-tabpanel-${index}`}
      aria-labelledby={`crowdstrike-tab-${index}`}
      {...other}
      style={{
        padding: '40px 72px 60px'
      }}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `crowdstrike-tab-${index}`,
    'aria-controls': `crowdstrike-tabpanel-${index}`
  }
}

export default AccountManagement
