import { FC, useEffect, useRef, useState } from 'react'
import {
  Box,
  // Checkbox,
  CircularProgress,
  // FormControlLabel,
  Typography
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MonthCalendar } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import theme from 'theme'

/* Utils */
import axios from '../../../../lib/axios'
import { openInNewTab, subtractMonths } from '../reports.utils'
// import { useFramework } from '../../../../hooks/useFramework'
// import { ConfiguredCPI, CPIFrequency, CPILibraryItem, CPIManifest, CPITimePeriod } from '../../../../models'

/* Components */
import TabsHeader from '../../../components/tabs-header'
import { ThemeButton } from '../../../components/buttons'
import {
  ActionsWrapper,
  // CpisList,
  CustomizerContainer,
  FormItemInfoWrapper,
  FormItemWrapper,
  FormWrapper,
  MonthPickerWrapper,
  // SelectCpisWrapper,
  Wrapper
} from '../reports.styles'
import { DownloadIcon, InfoIconLarge } from '../../../components/svg'
import ReportsMonthPickerSelectField from '../customize-report/components/ReportsMonthPickerSelectField'
// import CpiChip from '../../../components/cpi-chip'
// import { getPayloadTimePeriod } from '../../library/utils'
import {
  AllCPIsReportType,
  dateFormat
  // FunctionColorsForReports
  // getCpiCategoryColors
} from '../../../../lib/utils'
import { closeToast, openToast, ToastVariant } from '../../../../store/toasts/toastSlice'
import { useAppDispatch } from '../../../../store/hooks'

// const contentSectionsList = ['Data Trends', 'Descriptions', 'Formulas', 'Business Values', 'SLAs']

// interface CPIItem extends CPILibraryItem {
//   checked: boolean
// }
//
// interface Section {
//   name: string
//   checked: boolean
// }

const today = dayjs()
const todayMonthPref = dayjs(today).get('month')
const todayMonth = `${todayMonthPref + 1}`.padStart(2, '0')
const todayDay = dayjs(today).get('date')
const todayYear = dayjs(today).get('year')
const lastYear = new Date().getFullYear() - 1

const MonthlyReportBuilder: FC = () => {
  const dispatch = useAppDispatch()
  const closeNotification = useRef<any>(null)
  const [pickerOpen, setPickerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [firstCalendarValue, setFirstCalendarValue] = useState(dayjs(`${lastYear}-01-01`))
  const [secondCalendarValue, setSecondCalendarValue] = useState(dayjs(`${todayYear}-01-01`))
  const [selectedMonth, setSelectedMonth] = useState('')
  // const [activeCpis, setActiveCpis] = useState<CPIItem[]>([])
  // const [contentSections, setContentSections] = useState<Section[]>(
  //   contentSectionsList.map((n) => ({ name: n, checked: false }))
  // )
  // const [cpiConfiguration, setCpiConfiguration] = useState<ConfiguredCPI | null>(null)
  // const [manifestData, setManifestData] = useState<CPIManifest | null>(null)
  // const [fetchingCpi, setFetchingCpi] = useState(false)
  const [generatingCpiReport, setGeneratingCpiReport] = useState(false)
  const [code, setCode] = useState('')
  // const { framework, customFramework } = useFramework()

  const { data, error, isFetching } = useQuery(
    ['code', { code }],
    () => axios.get(`/api/v1/reports/download_url/${code}`),
    {
      refetchInterval: 5000,
      enabled: !!code
    }
  )

  useEffect(() => {
    if (error && (error as any).response?.status === 418) {
      setGeneratingCpiReport(false)
      setCode('')
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: (error as any).response?.data.error || 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
    }
  }, [error])

  useEffect(() => {
    if (data && !error && !isFetching) {
      const downloadUrl = data.data.reportDownloadUrl.url
      openInNewTab(`${downloadUrl}`)

      closeNotification.current = setTimeout(() => {
        dispatch(closeToast())
      }, 5000)
      setCode('')
    }
  }, [data, error, isFetching, code])

  useEffect(() => {
    if (!anchorEl) {
      closeCustomPicker()
    }
  }, [anchorEl])

  // const fetchListOfCPI = async () => {
  //   try {
  //     const allCpisRes = await axios.get('/api/v3/cpis')
  //     const activeList = allCpisRes.data.data.cpis.filter((item: CPILibraryItem) => item.configuration)
  //     setActiveCpis(activeList.map((n: CPILibraryItem) => ({ ...n, checked: false })))
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }
  //
  // useEffect(() => {
  //   fetchListOfCPI().catch((e) => e)
  // }, [])

  const closeCustomPicker = () => {
    setPickerOpen(false)
  }

  const handleChangeFirst = (newValue: any) => {
    setFirstCalendarValue(newValue)
    const month = dayjs(newValue).get('month')
    setSelectedMonth(`${lastYear}-${(month + 1).toString().padStart(2, '0')}`)
    setAnchorEl(null)
    setPickerOpen(false)
  }

  const handleChangeSecond = (newValue: any) => {
    setSecondCalendarValue(dayjs(newValue))
    const month = dayjs(newValue).get('month')
    setSelectedMonth(`${todayYear}-${(month + 1).toString().padStart(2, '0')}`)
    setAnchorEl(null)
    setPickerOpen(false)
  }

  // const handleCheckCpi = (e: any, cpi: CPIItem) => {
  //   const checked = e.target.checked
  //   const idx = activeCpis.indexOf(cpi)
  //   const result = activeCpis.map((n, i) => (i === idx ? { ...cpi, checked } : n))
  //
  //   setActiveCpis(result)
  // }
  //
  // const handleCheckSection = (e: any, section: Section) => {
  //   const checked = e.target.checked
  //   const idx = contentSections.indexOf(section)
  //   const result = contentSections.map((n, i) => (i === idx ? { ...section, checked } : n))
  //
  //   setContentSections(result)
  // }
  //
  // const handleSelectAllCpis = () => {
  //   setActiveCpis(activeCpis.map((n) => ({ ...n, checked: true })))
  // }
  //
  // const handleUnselectAllCpis = () => {
  //   setActiveCpis(activeCpis.map((n) => ({ ...n, checked: false })))
  // }
  //
  // const handleSelectAllSections = () => {
  //   setContentSections(contentSections.map((n) => ({ ...n, checked: true })))
  // }
  //
  // const handleUnselectAllSections = () => {
  //   setContentSections(contentSections.map((n) => ({ ...n, checked: false })))
  // }

  // const fetchSelectedCpi = async (cpiTitle: string) => {
  //   setFetchingCpi(true)
  //
  //   let cpiName = ''
  //   const foundOption = activeCpis.length > 0 ? activeCpis.find((n) => n.title === cpiTitle) : undefined
  //   if (foundOption) {
  //     cpiName = foundOption.name
  //   }
  //
  //   try {
  //     const cpiConfigRes = await axios.get<{ data: ConfiguredCPI }>(`/api/v3/cpis/${cpiName}`)
  //     const manifestRes = await axios.get<{ data: CPIManifest | null }>(`/api/v3/cpis/${cpiName}/details`)
  //     const {
  //       data: { data: cpiConfigData }
  //     } = cpiConfigRes
  //     const { data: manifestData } = manifestRes.data
  //     setCpiConfiguration(cpiConfigData)
  //     setManifestData(manifestData)
  //
  //     return {
  //       configuration: cpiConfigData,
  //       manifest: manifestData
  //     }
  //   } catch (e) {
  //     console.error(e)
  //   } finally {
  //     setFetchingCpi(false)
  //   }
  // }

  // const generateMonthlyReport = async () => {
  //   setGeneratingCpiReport(true)
  //
  //   const checkedCpis = activeCpis.filter((n) => n.checked)
  //   const cpiConfigurationsPromises = [...checkedCpis.map((n) => fetchSelectedCpi(n.title))]
  //   const result = await Promise.all(cpiConfigurationsPromises)
  //   const configurations = result && result.length > 0 ? result.map((n: any) => n.configuration) : []
  //   const manifests = result && result.length > 0 ? result.map((n: any) => n.manifest) : []

  //   try {
  //     if (!configurations.length || !manifests.length) return
  //
  //     // const promises = []
  //
  //     const promises = result.map(async ({ configuration, manifest }: any) => {
  //
  //       const integrationIds: string[] = configuration.integrations.map((n: any) => n.id)
  //
  //       const year = selectedMonth.slice(0, 4)
  //       const month = selectedMonth.slice(5, 7)
  //       const dateFrom = `${year}-${month}-01`
  //       const daysInMonth = dayjs(dateFrom).daysInMonth()
  //       const dateTo = `${year}-${month}-${daysInMonth}`
  //
  //       const payload: any = {
  //         cpi_name: manifest.name,
  //         frequency: 'daily',
  //         integration_ids: integrationIds,
  //         section: 'all',
  //         dateFrom,
  //         dateTo,
  //         ui: getCpiCategoryColors(manifest.categories[0] as string)
  //       }
  //
  //       return await axios.post(`/api/v1/reports/cpi`, payload)
  //     })
  //
  //     const resp = await Promise.all(promises)
  //
  //     if (resp && resp.length > 0) {
  //       const urls = resp.map((n) => n.data.reportDownloadUrl.url)
  //
  //       const promises2 = urls.map(async (c) => {
  //         const resUrl = await axios.get(`/api/v3/reports/download_url/${c}`)
  //       })
  //
  //       await Promise.all(promises2)
  //     }
  //
  //     // const integrationIds = cpiConfiguration.integrations.map((n: any) => n.id)
  //     // const integrationIds: string[] = []
  //     //
  //     // configurations.forEach((n) => {
  //     //   n.integrations.forEach((integration: any) => {
  //     //     if (!integrationIds.includes(integration.id)) {
  //     //       integrationIds.push(integration.id)
  //     //     }
  //     //   })
  //     // })
  //
  //     // const payload: any = {
  //     //   cpi_name: manifestData.name,
  //     //   frequency: selectedFrequency,
  //     //   integration_ids: integrationIds,
  //     //   section: selectedSeverity.includes('All') ? 'all' : selectedSeverity,
  //     //   timePeriod: getPayloadTimePeriod(selectedTimePeriod as CPITimePeriod),
  //     //   ui: getCpiCategoryColors(manifestData.categories[0] as string)
  //     // }
  //     // if (customTimePeriodValue.dateFrom && customTimePeriodValue.dateTo) {
  //     //   delete payload.timePeriod
  //     //   payload.dateFrom = customTimePeriodValue.dateFrom
  //     //   payload.dateTo = customTimePeriodValue.dateTo
  //     // }
  //
  //     // const res = await axios.post(`/api/v1/reports/cpi`, payload)
  //
  //     // if (res.status === 200) {
  //     // openInNewTab(res.data.reportDownloadUrl.url)
  //     // dispatch(
  //     //   openToast({
  //     //     variant: ToastVariant.success,
  //     //     props: {
  //     //       text: `${cpiValue} Report PDF Successfully Downloaded`,
  //     //       description: ''
  //     //     }
  //     //   })
  //     // )
  //     // navigate('/reports')
  //     // }
  //   } catch (e) {
  //     // dispatch(
  //     //   openToast({
  //     //     variant: ToastVariant.error,
  //     //     props: {
  //     //       text: 'Failed to Export Report',
  //     //       description: 'Please Contact Our Support Team'
  //     //     }
  //     //   })
  //     // )
  //   } finally {
  //     setGeneratingCpiReport(false)
  //     // closeNotification.current = setTimeout(() => {
  //     //   dispatch(closeToast())
  //     // }, 5000)
  //   }
  // }

  const generateFullReport = async () => {
    setGeneratingCpiReport(true)

    try {
      const year = selectedMonth.slice(0, 4)
      const month = selectedMonth.slice(5, 7)
      const dateFrom = `${year}-${month}-01`
      const daysInMonth = dayjs(dateFrom).daysInMonth()
      const dateTo = `${year}-${month}-${daysInMonth}`

      const payload = {
        dateFrom,
        dateTo,
        type: AllCPIsReportType.MONTHLY
      }

      const res = await axios.post(`/api/v1/reports/all_cpis`, payload)

      if (res.status.toString().startsWith('2')) {
        const { code } = res.data
        setCode(code)
      }
    } catch (e) {
      dispatch(
        openToast({
          variant: ToastVariant.error,
          props: {
            text: 'Failed to Export Report',
            description: 'Please Contact Our Support Team'
          }
        })
      )
    } finally {
      setGeneratingCpiReport(false)
    }
  }

  return (
    <CustomizerContainer>
      <TabsHeader
        tabValue={0}
        parents={['Reports']}
        handleTabChange={() => {
          //
        }}
        title="Customize a Monthly Report"
        tabLabels={[]}
        hideIntegrationsTabs={true}
      />

      <Wrapper>
        <FormWrapper>
          <FormItemWrapper>
            <Typography className="title">Select the month:</Typography>
            <ReportsMonthPickerSelectField
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              value={selectedMonth}
              disabled={false}
              pickerOpen={pickerOpen}
              setPickerOpen={setPickerOpen}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MonthPickerWrapper className="month-picker-monthly-wrapper" isopen={anchorEl ? 'true' : 'false'}>
                <Box className="month-calendar-container">
                  <Typography className="month-picker-monthly-title">{lastYear}</Typography>
                  <MonthCalendar
                    value={firstCalendarValue}
                    disableHighlightToday={true}
                    shouldDisableMonth={(currentDay) => {
                      const currentMonth = `${dayjs(currentDay).get('month') + 1}`.padStart(2, '0')
                      const currentYear = dayjs(currentDay).get('year')
                      const today = new Date()
                      const pastYear = subtractMonths(today, 13)
                      const currentDayFormatted = dayjs(currentDay).format(dateFormat.customLabel)

                      return (
                        (todayYear === currentYear && todayMonth === currentMonth && todayDay < 15) ||
                        new Date(currentDayFormatted) > today ||
                        new Date(currentDayFormatted) < pastYear
                      )
                    }}
                    onChange={handleChangeFirst}
                    monthsPerRow={4}
                    disableFuture
                    sx={{
                      '.MuiPickersMonth-root': {
                        '.MuiPickersMonth-monthButton': {
                          '&.Mui-selected': {
                            background: !selectedMonth.includes('2023') ? 'transparent !important' : '',
                            color: !selectedMonth.includes('2023')
                              ? '#fbd8f5 !important'
                              : `${theme.baseColors.primary[20]} !important`
                          },
                          '&.Mui-disabled': {
                            opacity: 0.38
                          }
                        }
                      }
                    }}
                  />
                </Box>
                <Box className="month-calendar-container">
                  <Typography className="month-picker-monthly-title">{todayYear}</Typography>
                  <MonthCalendar
                    value={secondCalendarValue}
                    onChange={handleChangeSecond}
                    monthsPerRow={4}
                    shouldDisableMonth={(currentDay) => {
                      const currentMonth = `${dayjs(currentDay).get('month') + 1}`.padStart(2, '0')
                      const currentYear = dayjs(currentDay).get('year')
                      const today = new Date()
                      const pastYear = subtractMonths(today, 13)
                      const currentDayFormatted = dayjs(currentDay).format(dateFormat.customLabel)

                      return (
                        (todayYear === currentYear && todayMonth === currentMonth && todayDay < 15) ||
                        new Date(currentDayFormatted) > today ||
                        new Date(currentDayFormatted) < pastYear
                      )
                    }}
                    disableFuture
                    sx={{
                      '.MuiPickersMonth-root': {
                        '.MuiPickersMonth-monthButton': {
                          '&.Mui-selected': {
                            background: !selectedMonth.includes('2024') ? 'transparent !important' : '',
                            color: !selectedMonth.includes('2024')
                              ? '#fbd8f5 !important'
                              : `${theme.baseColors.primary[20]} !important`
                          },
                          '&.Mui-disabled': {
                            opacity: 0.38
                          }
                        }
                      }
                    }}
                  />
                </Box>
              </MonthPickerWrapper>
            </LocalizationProvider>
            <FormItemInfoWrapper>
              <Box className="icon-wrapper">
                <InfoIconLarge />
              </Box>
              <Typography className="info-text">
                Note: Monthly reports are enabled after the 15th day of each month to ensure there is sufficient data
                available for accurate representation.
              </Typography>
            </FormItemInfoWrapper>
          </FormItemWrapper>
          {/*          <FormItemWrapper>
           <Typography className="title">Select Cyber Performance Indicators to include:</Typography>
           <SelectCpisWrapper>
           <Box className="controls">
           <ThemeButton
           themevariant="tertiary"
           onClick={handleSelectAllCpis}
           disabled={activeCpis.every((n) => n.checked)}
           >
           Select All
           </ThemeButton>
           <ThemeButton
           themevariant="tertiary"
           onClick={handleUnselectAllCpis}
           disabled={!activeCpis.some((n) => n.checked)}
           >
           Unselect All
           </ThemeButton>
           </Box>

           <CpisList>
           {activeCpis.map((cpi) => (
           <FormControlLabel
           control={
           <Checkbox
           checked={cpi.checked}
           onChange={(e) => handleCheckCpi(e, cpi)}
           inputProps={{ 'aria-label': 'controlled' }}
           />
           }
           label={
           <Box className="label-wrapper">
           <CpiChip text={cpi.display_name} sx={{ width: 'fit-content', whiteSpace: 'nowrap' }} />
           <Typography className="label-title">{cpi.title}</Typography>
           </Box>
           }
           />
           ))}
           </CpisList>
           </SelectCpisWrapper>
           </FormItemWrapper> */}

          {/* <FormItemWrapper>
           <Typography className="title">Select the content sections to include in the report</Typography>
           <SelectCpisWrapper>
           <Box className="controls">
           <ThemeButton
           themevariant="tertiary"
           onClick={handleSelectAllSections}
           disabled={contentSections.every((n) => n.checked)}
           >
           Select All
           </ThemeButton>
           <ThemeButton
           themevariant="tertiary"
           onClick={handleUnselectAllSections}
           disabled={!contentSections.some((n) => n.checked)}
           >
           Unselect All
           </ThemeButton>
           </Box>

           <CpisList>
           {contentSections.map((section) => (
           <FormControlLabel
           control={
           <Checkbox
           checked={section.checked}
           onChange={(e) => handleCheckSection(e, section)}
           inputProps={{ 'aria-label': 'controlled' }}
           />
           }
           label={
           <Box className="label-wrapper">
           <Typography className="label-title no-margin">{section.name}</Typography>
           </Box>
           }
           />
           ))}
           </CpisList>
           </SelectCpisWrapper>
           </FormItemWrapper> */}
        </FormWrapper>
        <ActionsWrapper sx={{ marginBottom: '50px' }}>
          <ThemeButton
            onClick={generateFullReport}
            // disabled={contentSections.every((n) => !n.checked) || activeCpis.every((n) => !n.checked) || !selectedMonth}
            disabled={!selectedMonth || generatingCpiReport || isFetching}
          >
            <>
              {generatingCpiReport || isFetching ? (
                <CircularProgress size={18} color="secondary" />
              ) : (
                <DownloadIcon
                  fill={!selectedMonth ? theme.baseColors.greyscale[80] : theme.baseColors.primary[20]}
                  // fill={
                  //   contentSections.every((n) => !n.checked) || activeCpis.every((n) => !n.checked) || !selectedMonth
                  //     ? theme.baseColors.greyscale[80]
                  //     : theme.baseColors.primary[20]
                  // }
                />
              )}
              Download PDF
            </>
          </ThemeButton>
        </ActionsWrapper>
      </Wrapper>
    </CustomizerContainer>
  )
}

export default MonthlyReportBuilder
