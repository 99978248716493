import { baseColors, baseUnit, iconSize, spacing, colorTokens } from './config'

export const colors = {
  primary: '#3c3854',
  primaryDark: '#1e1a1d',
  primaryDarker: '#131013',
  primaryLight: '#573f56',
  backgroundLight: 'linear-gradient(0deg, rgba(255, 169, 252, 0.08), rgba(255, 169, 252, 0.08)), #1e1a1d',
  backgroundMedium: 'linear-gradient(0deg, rgba(255, 169, 252, 0.12), rgba(255, 169, 252, 0.12)), #1e1a1d',
  backgroundDark: 'linear-gradient(0deg, rgba(255, 169, 252, 0.14), rgba(255, 169, 252, 0.14)), #1e1a1d',
  backgroundDarker: 'linear-gradient(0deg, rgba(255, 169, 252, 0.05) 0%, rgba(255, 169, 252, 0.05) 100%), #1e1a1d',
  ssmCardSkeleton:
    'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%)',
  white: '#ffffff',
  info: '#6abeed',
  warning: '#ffbc6c',
  infoLight: '#d3ceef',
  infoDark: '#b4Acfb',
  errorLight: '#f89b8e',
  warningLight: '#ffcc8e',
  primaryBorder: '#423d5c',
  secondaryDark: '#3f3b4d',
  rawSienneCore: '#f4ba94',
  purpleLight: '#e1bee7',
  purpleDark: '#23053a',
  neutral: '#e9e0e4',
  neutral100: '#cdc4c8',
  surfaceContainerBackground: '#292128',
  surfaceItemBackground: '#372a36',
  surfaceItemBackgroundLow: '#362e35',
  textPrimary: '#ffa9fc',
  textSecondaryLow: '#867083',
  borderLow: '#4d444b',
  borderMedium: '#372e35',
  controlBorder: baseColors.greyscale[60],
  hoverPrimary: '#452e45'
}

export const attributes = {
  boxShadow: {
    primary: '0 4px 8px 3px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.3)',
    secondary: '0 6px 10px 4px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.3)',
    tertiary: '0 4px 4px rgba(0, 0, 0, 0.25)',
    quaternary: '0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3)',
    toolbar: '0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.3)',
    feedSection: '0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15)',
    chipCircle: '0 4px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 12px 6px rgba(0, 0, 0, 0.15)',
    overallScoreWrapper: '0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 1px rgba(0, 0, 0, 0.15)',
    selectFieldPaper: '0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3)',
    navigation: '0 4px 8px 0 #1e1a1d'
  },
  borderRadius: {
    primary: '20px',
    secondary: '8px',
    tertiary: '12px',
    quaternary: '16px',
    small: '4px',
    mediumSmall: '8px',
    normal: '24px',
    circle: '50%',
    large: '100px'
  }
}

export const typography = {
  fontSize: {
    xSmall: '8px',
    small: '10px',
    mediumSmall: '11px',
    medium: '12px',
    large: '14px',
    xLarge: '16px',
    header: '20px',
    xxLarge: '22px',
    xxxLarge: '24px',
    xxxxLarge: '28px',
    epic: '32px',
    huge: '45px'
  },
  fontWeight: {
    normal: 400,
    bold: 500,
    thin: 510,
    thick: 590,
    extraThick: 600
  },
  fontFamily: {
    primary: 'Quicksand, sans-serif',
    secondary: 'Rubick',
    tertiary: 'Source Sans Pro'
  }
}
export const theme = {
  colors,
  attributes,
  baseColors,
  baseUnit,
  iconSize,
  spacing,
  colorTokens,
  typography
}
