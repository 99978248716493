import theme from 'theme'

const SidebarHomeIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.875 5.9375C1.875 5.41973 2.29473 5 2.8125 5H7.8125C8.33027 5 8.75 5.41973 8.75 5.9375V9.0625C8.75 9.58027 8.33027 10 7.8125 10H2.8125C2.29473 10 1.875 9.58027 1.875 9.0625V5.9375Z"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.875 7.1875C11.875 6.66973 12.2947 6.25 12.8125 6.25H17.1875C17.7053 6.25 18.125 6.66973 18.125 7.1875V14.0625C18.125 14.5803 17.7053 15 17.1875 15H12.8125C12.2947 15 11.875 14.5803 11.875 14.0625V7.1875Z"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.125 13.4375C3.125 12.9197 3.54473 12.5 4.0625 12.5H8.4375C8.95527 12.5 9.375 12.9197 9.375 13.4375V15.3125C9.375 15.8303 8.95527 16.25 8.4375 16.25H4.0625C3.54473 16.25 3.125 15.8303 3.125 15.3125V13.4375Z"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SidebarHomeIcon
