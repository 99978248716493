import {
  ListItemButton as MuiListItemButton,
  Collapse as MuiCollapse,
  Box,
  List as MuiList,
  ListItem as MuiListItem
} from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const Chip = styled(Box)<{ isNew: boolean }>`
  position: absolute;
  right: ${({ isNew }) => (isNew ? `${theme.spacing.small}px` : `${theme.spacing.xSmall}px`)};
  top: auto;
  padding: 2px ${theme.spacing.xSmall}px 2px ${theme.spacing.xsm}px;
  border-radius: 18px;
  background: ${theme.baseColors.info[10]};
  box-shadow: ${theme.attributes.boxShadow.quaternary};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xsm}px;
  opacity: 1;
  animation: cssAnimation 0.2s;

  .chip-title {
    color: ${theme.colors.white} !important;
    font-family: Quicksand, sans-serif !important;
    font-size: ${theme.typography.fontSize.medium} !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    letter-spacing: 0.5px !important;
  }

  @keyframes cssAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const LogoWrapper = styled(Box)`
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 64px;
  gap: ${theme.spacing.small}px;

  .sidebar_onyxia-logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .expanded-logo-wrapper {
    flex-grow: 1;
    display: flex;
    width: 100%;

    .onyxia-logo-title {
      color: ${theme.colors.neutral};
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.epic};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.extraThick};
      line-height: 40px;
    }
  }
`

export const Drawer = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 10001;
  opacity: 1;
  transition: width 0.2s ease-in-out;
`

export const SidebarContainer = styled(Box)`
  display: flex;
  height: 100%;
  transition: width 0.2s ease-in-out;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative;
  background: ${theme.colors.surfaceContainerBackground};
  border-radius: ${theme.attributes.borderRadius.secondary};
  box-shadow: ${theme.attributes.boxShadow.navigation};
  border-right: 1px solid ${theme.colors.borderMedium};
`

export const MainMenuWrapper = styled(Box)<{ isHome: boolean; isExpanded: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${({ isExpanded }) => (!isExpanded ? `0 ${theme.spacing.md}px` : `0 ${theme.spacing.small}px`)};

  ${({ isHome }) =>
    isHome &&
    `
      margin-top: 0;
  `};

  ${({ isExpanded }) =>
    isExpanded &&
    `
      align-items: flex-start;
  `}
`

export const List = styled(MuiList)<{ isExpanded?: boolean }>`
  padding: 0;
  width: 100%;

  &.footer-list {
    padding: ${({ isExpanded }) => (!isExpanded ? `0 ${theme.spacing.md}px` : `0 ${theme.spacing.small}px`)};
  }
`

export const ListItem = styled(MuiListItem)<{ isselected?: string; expanded?: string }>`
  padding: ${theme.spacing.sm}px;
  border-radius: ${theme.attributes.borderRadius.tertiary};
  background: ${({ isselected }) => (isselected && isselected === 'true' ? theme.colors.primaryLight : 'transparent')};
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: ${({ expanded }) => (expanded && expanded === 'true' ? '2px' : `${theme.spacing.small}px`)};
  }

  &.sublist-item {
    margin-bottom: 2px;

    &:last-of-type {
      margin-bottom: ${theme.spacing.small}px;
    }

    &.footer-sublist-item {
      &:last-of-type {
        margin-bottom: 2px;
      }
    }
  }

  &:hover {
    background: ${theme.colors.hoverPrimary};
    cursor: pointer;
  }

  &.footer-list-item {
    margin-bottom: ${theme.spacing.small}px;
  }

  .arrow-icon {
    width: 24px;
    height: 24px;

    &.is-selected {
      svg {
        path {
          fill: ${theme.colors.white};
        }
      }
    }

    &.no-rotate {
      transform: rotate(90deg);
    }

    &.rotate {
      transform: rotate(-90deg);
    }
  }
`

export const ListItemButton = styled(MuiListItemButton)<{
  selected?: boolean
  isexpanded?: string
  hassubmenu?: string
  isProfile?: boolean
  isselected?: string
  open?: string
  parentselected?: string
}>`
  position: relative;
  gap: ${theme.spacing.sm}px;
  border-radius: ${theme.attributes.borderRadius.large};
  height: 40px;
  padding: 0;
  width: 100%;
  min-width: calc(100% - ${theme.spacing.medium}px);
  max-width: unset;
  flex-grow: 1;
  justify-content: center;

  &.sublist-item-button {
    padding-left: 30px;
  }

  &.footer-sublist-button {
    padding-left: 0;
    justify-content: flex-start;
    gap: ${theme.spacing.xSmall}px !important;
    width: 100%;

    svg {
      path {
        fill: ${theme.baseColors.greyscale[80]};
      }
    }
  }

  &.footer-sublist-item-button {
    padding-left: 30px;
  }

  &.footer-list-item-button {
    justify-content: ${({ isexpanded }) => (isexpanded && isexpanded === 'true' ? 'flex-start' : 'center')};

    .avatar-details-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-flow: column wrap;
      gap: 0;

      .MuiListItemText-root {
        margin: 0;
      }
    }
  }

  .MuiListItemText-root {
    .MuiTypography-root {
      font-family: ${theme.typography.fontFamily.primary};
      font-size: ${theme.typography.fontSize.large};
      font-style: normal;
      font-weight: ${theme.typography.fontWeight.extraThick};
      line-height: 20px;
      letter-spacing: 0.1px;
      overflow: hidden;
      white-space: nowrap;
      opacity: 1;
      transition: all 0.2s ease-in-out;
      color: ${({ isselected, parentselected }) =>
        (isselected && isselected === 'true') || (parentselected && parentselected === 'true')
          ? theme.colors.white
          : theme.baseColors.greyscale[80]};
    }
  }

  .MuiListItemIcon-root {
    min-width: unset;

    ${({ selected }) =>
      selected &&
      `
        svg {
          path {
            fill: ${theme.colors.white};
          }
        }
    `};
  }
`

export const Collapse = styled(MuiCollapse)<{ selected?: boolean }>`
  transition: all 0.2s ease-in-out;
`
