import { Dispatch, SetStateAction } from 'react'
import {
  ChartData,
  ChartDataExtended,
  CPIFrequency,
  CPISeverity,
  CPITimePeriod,
  CustomLabelDate,
  D3Position,
  DataPoint,
  DataStatus,
  Label,
  LabelCount,
  VisibleLabel
} from '../../../../../../models'
import { formatPerformanceUnit, formatTooltipDate, getUtcDateAndTime } from '../../../utils'
import * as d3 from 'd3'
import {
  appendCartesianGridHorizontal,
  appendDefs,
  appendEventIndicators,
  appendFilledAreaAndStroke,
  appendSLAElements,
  appendStripes,
  appendXYAxis
} from './chart-components'
import {
  appendCartesianGridHorizontalOC,
  appendSlaElementsOC,
  appendStripesOC,
  appendXYAxisOC
} from './chart-components-oc'
import {
  appendFilledAreaStroke,
  appendHealthLines,
  appendHoverPointCircles,
  appendHoverPointLine,
  appendRecentPerformanceLine
} from './chart-components-shared'
import { checkIsGreen, getHealthDirection } from '../../../../../../lib/utils'
import theme from 'theme'

export const redStroke = theme.baseColors.info[50]
export const greenStroke = theme.baseColors.info[20]
export const chartHeightStart = 16
export const xAxisHeight = 80

export const multiplicativeValueCpis = [
  'cpi034',
  'cpi035',
  'cpi052',
  'cpi053',
  'cpi054',
  'cpi055',
  'cpi056',
  'cpi057',
  'cpi061',
  'cpi062'
]

export const getYLine = (chartData: ChartData | null | undefined, maxValueY: number) => {
  if (chartData && chartData.values && chartData.values.length > 0) {
    return chartData.sla
  }
  return maxValueY / 2
}

export const getLineColor = (chartData: ChartData | null | undefined, position: 'top' | 'bottom') => {
  if (!chartData) return ''

  const healthDirection = getHealthDirection(
    chartData.zoneColor,
    Number(new Date(chartData.recentValue).getTime()),
    chartData.sla
  )
  if (position === 'top') {
    if (healthDirection === 'desc') {
      return redStroke
    } else {
      return greenStroke
    }
  }
  if (position === 'bottom') {
    if (healthDirection === 'desc') {
      return greenStroke
    } else {
      return redStroke
    }
  }

  return redStroke
}

export const getAreaFill = (chartData: ChartData | null | undefined) => {
  if (!chartData) return ''

  if (chartData.status === DataStatus.Initializing) {
    return '#yellowGradient'
  }
  if (chartData.status === DataStatus.Recalculating) {
    return '#blueGradient'
  }

  if (typeof Number(chartData.recentValue) !== 'number') return ''
  if (typeof Number(chartData.sla) !== 'number') return ''

  const healthDirection = getHealthDirection(chartData.zoneColor, Number(chartData.recentValue), chartData.sla)
  const isGreen = checkIsGreen(healthDirection, Number(chartData.recentValue), chartData.sla)
  if (isGreen) {
    return '#greenGradient'
  } else {
    return '#redGradient'
  }
}

export const getAreaStroke = (chartData: ChartData | null | undefined, prediction = false) => {
  if (!chartData) return ''

  if (prediction) return '#BB95EA'
  if (chartData.status === DataStatus.Initializing) {
    return theme.colors.white
  }
  if (chartData.status === DataStatus.Recalculating) {
    return theme.baseColors.info[30]
  }

  if (typeof Number(chartData.recentValue) !== 'number') return ''
  if (typeof Number(chartData.sla) !== 'number') return ''

  const healthDirection = getHealthDirection(chartData.zoneColor, Number(chartData.recentValue), chartData.sla)
  const isGreen = checkIsGreen(healthDirection, Number(chartData.recentValue), chartData.sla)
  if (isGreen) {
    return greenStroke
  } else {
    return redStroke
  }
}

export const getDataStartsAt = (
  firstValue: string,
  weeklyGrouped: ChartDataExtended | null,
  selectedFrequency: CPIFrequency
) => {
  if (selectedFrequency === CPIFrequency.Weekly && weeklyGrouped) {
    return getUtcDateAndTime(weeklyGrouped.values[0].start).labelFormatDate
  }

  return getUtcDateAndTime(firstValue).labelFormatDate
}

interface ShowHoverElementsProps {
  svg:
    | d3.Selection<SVGSVGElement | null, unknown, null, undefined>
    | d3.Selection<SVGGElement, unknown, null, undefined>
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
  selectedTimePeriod: CPITimePeriod
  dataPoint: DataPoint
  stripeWidth: number
  isHoveringXAxis: boolean
  isOc?: boolean
}

const showHoverElements = ({
  svg,
  tooltip,
  selectedTimePeriod,
  dataPoint,
  stripeWidth,
  isHoveringXAxis,
  isOc
}: ShowHoverElementsProps) => {
  const index = selectedTimePeriod === CPITimePeriod.Week ? dataPoint.index : dataPoint.monthIndex
  if (typeof index === 'undefined') return

  const circleInner = d3.select('.hover-circle-inner')
  const circleOuter = d3.select('.hover-circle-outer')

  /* Add Button */
  const addIndicatorCircle = svg.select('.add-button-circle')
  addIndicatorCircle.attr('display', 'block').attr('cx', stripeWidth * index + stripeWidth / 2 + 20)
  const addIndicatorRectHor = svg.select('.add-button-rect-hor')
  addIndicatorRectHor.attr('x', stripeWidth * index + stripeWidth / 2 + 14.5)
  const addIndicatorRectVer = svg.select('.add-button-rect-ver')
  addIndicatorRectVer.attr('x', stripeWidth * index + stripeWidth / 2 + 18.5)

  /* Add Button Tooltip */
  const addIndicatorRectTooltipWrapper = svg.select('.add-button-rect-tooltip-wrapper')
  addIndicatorRectTooltipWrapper.attr('x', stripeWidth * index + stripeWidth / 2 - 45)
  const addIndicatorRectTooltipText = svg.select('.add-button-rect-tooltip-text')
  addIndicatorRectTooltipText.attr('x', stripeWidth * index + stripeWidth / 2 - 39)

  /* Count Tooltip */
  const countRectTooltipWrapper = svg.select('.count-rect-tooltip-wrapper')
  countRectTooltipWrapper.attr('x', stripeWidth * index + stripeWidth / 2 - 65)
  const countRectTooltipText = svg.select('.count-rect-tooltip-text')
  countRectTooltipText.attr('x', stripeWidth * index + stripeWidth / 2 - 54 + 'px')

  if (!isHoveringXAxis) {
    tooltip.style('display', 'block')
  }

  circleInner.attr('cx', dataPoint.x)
  circleInner.attr('cy', dataPoint.y)
  circleInner.attr(
    'display',
    isOc && typeof dataPoint.value === 'number' ? 'block' : dataPoint.value && dataPoint.value > 0 ? 'block' : 'none'
  )
  circleOuter.attr('cx', dataPoint.x)
  circleOuter.attr('cy', dataPoint.y)
  circleOuter.attr(
    'display',
    isOc && typeof dataPoint.value === 'number' ? 'block' : dataPoint.value && dataPoint.value > 0 ? 'block' : 'none'
  )

  const cursorLine = svg.select('.cursor-line')
  cursorLine.attr('x1', dataPoint.x).attr('x2', dataPoint.x).style('display', 'block')
}

interface OnMouseMoveProps extends ShowHoverElementsProps {
  event: any
  d: any
  healthUnit: string
  tooltipUnit: string
  selectedFrequency: CPIFrequency
  selectedSeverity: CPISeverity
  isNoSeveritiesCpi: boolean
  dataStartsAt: string
  ocTooltip?: boolean
  cpi?: string
  mouseX: number
  mouseY: number
  predictionTooltip?: boolean
}

/*
 * Hover Tooltip
 * */
export const onMouseMove = ({
  cpi,
  svg,
  tooltip,
  selectedTimePeriod,
  dataPoint,
  stripeWidth,
  isHoveringXAxis,
  event,
  d,
  healthUnit,
  tooltipUnit,
  selectedFrequency,
  selectedSeverity,
  isNoSeveritiesCpi,
  dataStartsAt,
  ocTooltip = false,
  mouseX,
  mouseY,
  predictionTooltip
}: OnMouseMoveProps) => {
  if (!d) return

  showHoverElements({
    svg,
    tooltip,
    selectedTimePeriod,
    dataPoint,
    stripeWidth,
    isHoveringXAxis,
    isOc: ocTooltip
  })

  let unitInfoText =
    healthUnit === 'percent'
      ? `% of ${tooltipUnit}: ${d?.numerator ?? 0} / ${d?.denominator ?? 0}`
      : `Number of ${tooltipUnit}: ${d?.denominator ?? 0}`

  if (['cpi024', 'cpi023', 'cpi022'].includes(<string>cpi)) {
    unitInfoText =
      healthUnit === 'percent'
        ? `Number of Emails: ${d?.numerator ?? 0} / ${d?.denominator ?? 0}`
        : `Number of ${tooltipUnit}: ${d?.denominator ?? 0}`
  } else if (cpi === 'cpi021') {
    unitInfoText =
      healthUnit === 'percent'
        ? `Number of Training Assignments: ${d?.numerator ?? 0} / ${d?.denominator ?? 0}`
        : `Number of ${tooltipUnit}: ${d?.denominator ?? 0}`
  }

  if (ocTooltip) {
    unitInfoText =
      healthUnit === 'percent'
        ? `% of ${tooltipUnit}: ${d?.numerator ?? 0} / ${d?.denominator ?? 0}`
        : `Number of open/closed ${tooltipUnit}: ${d?.numerator ?? 0} / ${d?.denominator ?? 0}`
  }
  let formattedDate = formatTooltipDate(
    selectedFrequency === CPIFrequency.Weekly ? d.start : d.xValue,
    d.end,
    selectedFrequency,
    selectedTimePeriod,
    dataStartsAt
  )
  const splitDates = formattedDate.split('-')
  if (splitDates.length === 2 && splitDates[0] === splitDates[1]) {
    formattedDate = splitDates[0]
  }

  const pageX = mouseX
  const innerHeight = window?.innerHeight || 0
  const innerPageY = event.pageY
  const padding = 180
  const isBelowVisibleScreen = innerPageY + padding > innerHeight
  const pageY = isBelowVisibleScreen ? mouseY - 145 : mouseY

  if (predictionTooltip) {
    let left = pageX < 375 ? pageX - 10 + 'px' : pageX - 220 + 'px'
    const top = pageY - 128 + 'px'

    if (d.prediction) {
      left = pageX < 375 ? pageX - 50 + 'px' : pageX - 310 + 'px'

      tooltip
        .html(
          `
            <div class="prediction-text-wrapper">
              <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_dd_1_3292)">
                <circle cx="18.5" cy="18.5" r="11.5" fill="url(#paint0_linear_1_3292)"/>
                <circle cx="18.5" cy="18.5" r="11.2946" stroke="#BB95EA" stroke-width="0.410714"/>
                </g>
                <g clip-path="url(#clip0_1_3292)">
                <path d="M21.9503 16.7749L22.6691 15.1937L24.2503 14.4749L22.6691 13.7562L21.9503 12.1749L21.2316 13.7562L19.6503 14.4749L21.2316 15.1937L21.9503 16.7749Z" fill="white"/>
                <path d="M21.9503 20.2249L21.2316 21.8062L19.6503 22.5249L21.2316 23.2437L21.9503 24.8249L22.6691 23.2437L24.2503 22.5249L22.6691 21.8062L21.9503 20.2249Z" fill="white"/>
                <path d="M17.6378 17.0624L16.2003 13.8999L14.7628 17.0624L11.6003 18.4999L14.7628 19.9374L16.2003 23.0999L17.6378 19.9374L20.8003 18.4999L17.6378 17.0624ZM16.7696 19.0692L16.2003 20.3227L15.6311 19.0692L14.3776 18.4999L15.6311 17.9307L16.2003 16.6772L16.7696 17.9307L18.0231 18.4999L16.7696 19.0692Z" fill="white"/>
                </g>
                <defs>
                <filter id="filter0_dd_1_3292" x="0.839286" y="0.839286" width="35.3214" height="35.3214" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="3.08036"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.305882 0 0 0 0 0.85098 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_3292"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="1.02679"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.556863 0 0 0 0 0.305882 0 0 0 0 0.85098 0 0 0 1 0"/>
                <feBlend mode="normal" in2="effect1_dropShadow_1_3292" result="effect2_dropShadow_1_3292"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_3292" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_1_3292" x1="18.5" y1="7" x2="18.5" y2="30" gradientUnits="userSpaceOnUse">
                <stop stop-color="#010002"/>
                <stop offset="0.275" stop-color="#56406D"/>
                <stop offset="0.53"/>
                </linearGradient>
                <clipPath id="clip0_1_3292">
                <rect width="13.8" height="13.8" fill="white" transform="translate(11.0254 11.6)"/>
                </clipPath>
                </defs>
              </svg>
              <p class='tooltip-performance'>${formattedDate}</p>
            </div>
            <div class="tooltip-units-wrapper">
              <p class='tooltip-units-prediction'>Predicted Performance:</p>
              <p class='tooltip-units-prediction'>${Math.round((d.yValue + Number.EPSILON) * 100) / 100}%</p>
            </div>
          `
        )
        .style('left', left)
        .style('top', top)

      return
    } else {
      left = pageX < 375 ? pageX - 15 + 'px' : pageX - 265 + 'px'

      tooltip
        .html(
          `
            <p class='tooltip-performance prediction-date'>${formattedDate}<AIPredictionIcon /></p>
            <div class="tooltip-units-wrapper">
              <p class='tooltip-units-prediction'>Performance:</p>
              <p class='tooltip-units-prediction'>${Math.round((d.yValue + Number.EPSILON) * 100) / 100}%</p>
            </div>
          `
        )
        .style('left', left)
        .style('top', top)

      return
    }
  }

  if (isNoSeveritiesCpi) {
    tooltip
      .html(
        `${formattedDate}
              <p class='tooltip-performance'>
                Performance: ${d.yValue}${formatPerformanceUnit(healthUnit)}
              </p>
          <p class='tooltip-units'>${unitInfoText}</p>`
      )
      .style('left', pageX < 375 ? pageX + 20 + 'px' : pageX - 200 + 'px')
      .style('top', pageY + 25 + 'px')
  } else {
    let left = pageX < 375 ? pageX + 20 + 'px' : pageX - 275 + 'px'
    let top = pageY + 25 + 'px'

    if (ocTooltip) {
      left = pageX < 375 ? pageX + 20 + 'px' : pageX - 325 + 'px'
      top = pageY + 25 + 'px'
    }

    if (cpi === 'cpi061') {
      top = pageY - 40 + 'px'
      left = pageX < 375 ? pageX + 20 + 'px' : pageX - 240 + 'px'

      tooltip
        .html(
          `${formattedDate}
              <p class='tooltip-performance'>
                Performance (<span class='severity-text'>${selectedSeverity}</span>): ${d.yValue} Events
              </p>
              <p class='tooltip-units'>${unitInfoText}</p>
              <p class='tooltip-units'>Number of Allowed Events: ${d.numberAllowed ?? 0}</p>
              <p class='tooltip-units'>Number of Blocked Event: ${d.numberBlocked ?? 0}</p>
              <p class='tooltip-units'>Number of Other Events: ${d.numberOthers ?? 0}</p>
`
        )
        .style('left', left)
        .style('top', top)
    } else {
      tooltip
        .html(
          `${formattedDate}
              <p class='tooltip-performance'>
                Performance (<span class='severity-text'>${selectedSeverity} ${
            selectedSeverity === 'all' ? 'Severities' : 'Severity'
          }</span>): ${d.yValue}${formatPerformanceUnit(healthUnit)}
              </p>
              <p class='tooltip-units'>${unitInfoText}</p>`
        )
        .style('left', left)
        .style('top', top)
    }
  }
}

interface AppendTrendsChartElementsProps {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
  xScaleGroup: d3.ScaleBand<string>
  yScale: d3.ScaleLinear<number, number, never>
  positions: D3Position[]
  pathStr: string
  uniqueMonths: string[]
  filteredData: any[]
  data: ChartData
  groupedByWeeks: ChartDataExtended | null
  pinnedLabels?: Label[]
  dataDates: LabelCount[]
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  selectedSeverity: CPISeverity
  width: number
  height: number
  chartHeightStart: number
  stripeWidth: number
  healthBorder: number
  healthUnit: string
  maxYValue: number
  maxYScaleValue: number
  marginTop: number
  marginBottom: number
  offsetLeft: number
  dataStartsAt: string
  getAreaFill: (chartData: ChartData | null | undefined) => string
  getAreaStroke: (chartData: ChartData | null | undefined, prediction?: boolean) => string
  getLineColor: (chartData: ChartData | null | undefined, position: 'top' | 'bottom') => string
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  setCustomDate: Dispatch<SetStateAction<CustomLabelDate>>
  setRedirectToMode: Dispatch<SetStateAction<string>>
  chartData: ChartData | null | undefined
  visibleLabels: VisibleLabel[]
  sectionsDisplayName: string
  sectionsDisplayNameSingular: string
  isNoSeveritiesCpi: boolean
  hideEventIndicators?: boolean
}

export const appendTrendsChartElements = ({
  svg,
  tooltip,
  xScaleGroup,
  sectionsDisplayName,
  sectionsDisplayNameSingular,
  isNoSeveritiesCpi,
  yScale,
  positions,
  pathStr,
  uniqueMonths,
  filteredData,
  data,
  groupedByWeeks,
  pinnedLabels,
  dataDates,
  selectedTimePeriod,
  selectedFrequency,
  selectedSeverity,
  width,
  height,
  chartHeightStart,
  stripeWidth,
  healthUnit,
  healthBorder,
  maxYValue,
  maxYScaleValue,
  marginTop,
  marginBottom,
  offsetLeft,
  dataStartsAt,
  getAreaFill,
  getAreaStroke,
  getLineColor,
  setDrawerOpen,
  setMode,
  setCustomDate,
  setRedirectToMode,
  chartData,
  visibleLabels,
  hideEventIndicators
}: AppendTrendsChartElementsProps) => {
  const { recentValue, sla } = data
  const maxY = maxYScaleValue === 0 ? maxYValue : healthUnit === 'percent' ? maxYScaleValue : maxYValue

  const lineColorTop = getLineColor(chartData, 'top')
  const lineColorBottom = getLineColor(chartData, 'bottom')
  const areaFill = getAreaFill(chartData)
  const areaStroke = getAreaStroke(chartData)

  let rpYPercentage = 0
  if (healthUnit !== 'percent') {
    rpYPercentage = recentValue / maxY
  }
  if (healthUnit === 'percent') {
    rpYPercentage = recentValue / 100
  }

  let recentPerformanceYPositionValue =
    rpYPercentage === 0 ? height : height - rpYPercentage * height + chartHeightStart
  if (healthUnit === 'percent' && rpYPercentage !== 0 && recentValue < maxYValue / 2) {
    recentPerformanceYPositionValue = recentPerformanceYPositionValue - chartHeightStart
  } else if (healthUnit === 'percent' && rpYPercentage !== 0 && recentValue > maxYValue / 2) {
    recentPerformanceYPositionValue = recentPerformanceYPositionValue - 5
  } else if (healthUnit !== 'percent' && rpYPercentage !== 0 && recentValue <= maxYValue / 2) {
    if (rpYPercentage.toString() === '0.5') {
      recentPerformanceYPositionValue = recentPerformanceYPositionValue + chartHeightStart
    } else {
      recentPerformanceYPositionValue = recentPerformanceYPositionValue - chartHeightStart
    }
  }
  if (recentPerformanceYPositionValue > height) {
    recentPerformanceYPositionValue = height
  }
  if (recentPerformanceYPositionValue < chartHeightStart) {
    recentPerformanceYPositionValue = chartHeightStart
  }
  /* Check for Recent Performance Line */
  if (recentValue === maxYValue / 2) {
    recentPerformanceYPositionValue = height / 2 + chartHeightStart / 2
  }

  appendDefs(svg)
  appendHoverPointCircles(svg)
  appendHoverPointLine({ svg, tooltip, height, chartHeightStart })
  appendCartesianGridHorizontal({ svg, width, height, chartHeightStart })
  appendFilledAreaAndStroke({
    svg,
    tooltip,
    positions,
    pathStr,
    height,
    filteredData,
    areaFill,
    areaStroke
  })
  appendHealthLines({ svg, height, chartHeightStart, healthBorder, lineColorTop, lineColorBottom })
  appendRecentPerformanceLine({
    svg,
    width,
    chartHeightStart,
    status: data.status,
    recentPerformanceYPositionValue
  })
  appendStripes({
    svg,
    tooltip,
    xScaleGroup,
    uniqueMonths,
    height,
    marginTop,
    marginBottom,
    setDrawerOpen,
    setMode,
    offsetLeft,
    stripeWidth,
    selectedTimePeriod,
    selectedFrequency,
    filteredData,
    groupedByWeeks,
    setCustomDate,
    setRedirectToMode,
    dataStartsAt
  })
  appendSLAElements({
    svg,
    sectionsDisplayName,
    sectionsDisplayNameSingular,
    isNoSeveritiesCpi,
    selectedSeverity,
    width,
    height,
    healthBorder,
    sla,
    healthUnit,
    visibleLabels
  })
  appendXYAxis({
    svg,
    xScaleGroup,
    yScale,
    data,
    filteredData,
    selectedTimePeriod,
    selectedFrequency,
    dataStartsAt,
    height,
    healthUnit
  })
  if (!hideEventIndicators) {
    appendEventIndicators({
      svg,
      uniqueMonths,
      pinnedLabels,
      selectedTimePeriod,
      selectedFrequency,
      filteredData,
      groupedByWeeks,
      dataDates,
      height,
      stripeWidth,
      setDrawerOpen,
      setMode,
      offsetLeft,
      setCustomDate,
      setRedirectToMode,
      dataStartsAt,
      status: chartData?.status
    })
  }
}

interface AppendPredictionTrendsChartElementsProps {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
  xScaleGroup: d3.ScaleBand<string>
  yScale: d3.ScaleLinear<number, number, never>
  pathStr: string
  pathStrPrediction: string
  uniqueMonths: string[]
  filteredData: any[]
  data: ChartData
  groupedByWeeks: ChartDataExtended | null
  pinnedLabels?: Label[]
  dataDates: LabelCount[]
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  selectedSeverity: CPISeverity
  width: number
  height: number
  chartHeightStart: number
  stripeWidth: number
  healthBorder: number
  healthUnit: string
  maxYValue: number
  maxYScaleValue: number
  marginTop: number
  marginBottom: number
  offsetLeft: number
  dataStartsAt: string
  getAreaFill: (chartData: ChartData | null | undefined) => string
  getAreaStroke: (chartData: ChartData | null | undefined, prediction?: boolean) => string
  getLineColor: (chartData: ChartData | null | undefined, position: 'top' | 'bottom') => string
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  setRedirectToMode: Dispatch<SetStateAction<string>>
  chartData: ChartData | null | undefined
  visibleLabels: VisibleLabel[]
  sectionsDisplayName: string
  sectionsDisplayNameSingular: string
  isNoSeveritiesCpi: boolean
  hideEventIndicators?: boolean
}

export const appendPredictionTrendsChartElements = ({
  svg,
  tooltip,
  xScaleGroup,
  sectionsDisplayName,
  sectionsDisplayNameSingular,
  isNoSeveritiesCpi,
  yScale,
  pathStr,
  pathStrPrediction,
  uniqueMonths,
  filteredData,
  data,
  groupedByWeeks,
  selectedTimePeriod,
  selectedFrequency,
  selectedSeverity,
  width,
  height,
  chartHeightStart,
  stripeWidth,
  healthUnit,
  healthBorder,
  maxYValue,
  maxYScaleValue,
  marginTop,
  marginBottom,
  offsetLeft,
  dataStartsAt,
  getAreaStroke,
  getLineColor,
  setDrawerOpen,
  setMode,
  setRedirectToMode,
  chartData,
  visibleLabels
}: AppendPredictionTrendsChartElementsProps) => {
  const { recentValue, sla } = data
  const maxY = maxYScaleValue === 0 ? maxYValue : healthUnit === 'percent' ? maxYScaleValue : maxYValue
  const lineColorTop = getLineColor(chartData, 'top')
  const lineColorBottom = getLineColor(chartData, 'bottom')
  const areaStroke = getAreaStroke(chartData)
  const predictionAreaStroke = getAreaStroke(chartData, true)

  let rpYPercentage = 0
  if (healthUnit !== 'percent') {
    rpYPercentage = recentValue / maxY
  }
  if (healthUnit === 'percent') {
    rpYPercentage = recentValue / 100
  }

  let recentPerformanceYPositionValue =
    rpYPercentage === 0 ? height : height - rpYPercentage * height + chartHeightStart
  if (healthUnit === 'percent' && rpYPercentage !== 0 && recentValue < maxYValue / 2) {
    recentPerformanceYPositionValue = recentPerformanceYPositionValue - chartHeightStart
  } else if (healthUnit === 'percent' && rpYPercentage !== 0 && recentValue > maxYValue / 2) {
    recentPerformanceYPositionValue = recentPerformanceYPositionValue - 5
  } else if (healthUnit !== 'percent' && rpYPercentage !== 0 && recentValue <= maxYValue / 2) {
    recentPerformanceYPositionValue = recentPerformanceYPositionValue - 5
  }
  if (recentPerformanceYPositionValue > height) {
    recentPerformanceYPositionValue = height
  }
  if (recentPerformanceYPositionValue < chartHeightStart) {
    recentPerformanceYPositionValue = chartHeightStart
  }

  appendDefs(svg)
  appendHoverPointCircles(svg)
  appendHoverPointLine({ svg, tooltip, height, chartHeightStart })
  appendCartesianGridHorizontal({ svg, width, height, chartHeightStart })

  const existingFilledAreaStroke = svg.selectAll('.filled-area-stroke')
  if (!existingFilledAreaStroke.empty()) existingFilledAreaStroke.remove()

  const existingFilledAreaStroke2 = svg.selectAll('.filled-area-stroke-prediction')
  if (!existingFilledAreaStroke2.empty()) existingFilledAreaStroke2.remove()

  svg
    .append('path')
    .attr('fill', 'none')
    .attr('stroke', `${areaStroke}`)
    .attr('d', pathStr)
    .attr('class', 'filled-area-stroke')
    .attr('stroke-width', 3)
    .attr('stroke-linejoin', 'bevel')
  svg
    .append('path')
    .attr('fill', 'none')
    .attr('stroke', `${predictionAreaStroke}`)
    .attr('d', `${pathStrPrediction.replace('L', 'M')}`)
    .attr('class', 'filled-area-stroke-prediction')
    .attr('stroke-width', 3)
    .attr('stroke-linejoin', 'bevel')

  appendHealthLines({ svg, height, chartHeightStart, healthBorder, lineColorTop, lineColorBottom })
  appendRecentPerformanceLine({
    svg,
    width,
    chartHeightStart,
    status: data.status,
    recentPerformanceYPositionValue
  })
  appendStripes({
    svg,
    tooltip,
    xScaleGroup,
    uniqueMonths,
    height,
    marginTop,
    marginBottom,
    setDrawerOpen,
    setMode,
    offsetLeft,
    stripeWidth,
    selectedTimePeriod,
    selectedFrequency,
    filteredData,
    groupedByWeeks,
    setRedirectToMode,
    dataStartsAt
  })
  appendSLAElements({
    svg,
    sectionsDisplayName,
    sectionsDisplayNameSingular,
    isNoSeveritiesCpi,
    selectedSeverity,
    width,
    height,
    healthBorder,
    sla,
    healthUnit,
    visibleLabels
  })
  appendXYAxis({
    svg,
    xScaleGroup,
    yScale,
    data,
    filteredData,
    selectedTimePeriod,
    selectedFrequency,
    dataStartsAt,
    height,
    healthUnit
  })
}

interface AppendTrendsChartOCElementsProps {
  svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
  tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any>
  xScaleGroup: d3.ScaleBand<string>
  yScale: d3.ScaleLinear<number, number, never>
  groupedByWeeks: ChartDataExtended | null
  uniqueMonths: string[]
  selectedTimePeriod: CPITimePeriod
  selectedFrequency: CPIFrequency
  selectedSeverity: CPISeverity
  healthBorder: number
  filteredData: any[]
  dataStartsAt: string
  width: number
  height: number
  chartHeightStart: number
  healthUnit: string
  noData: boolean
  offsetLeft: number
  stripeWidth: number
  setCustomDate: ({ date, start, end }: CustomLabelDate) => void
  setRedirectToMode: Dispatch<SetStateAction<string>>
  setDrawerOpen: Dispatch<SetStateAction<boolean>>
  setMode: Dispatch<SetStateAction<string>>
  marginTop: number
  marginBottom: number
  getLineColor: (chartData: ChartData | null | undefined, position: 'top' | 'bottom') => string
  maxYValue: number
  maxValueY: number
  max: number
  getAreaStroke: (chartData: ChartData | null | undefined) => string
  pathStr: string
  pinnedLabels?: Label[]
  dataDates: LabelCount[]
  data: ChartData
  chartData: ChartData | null | undefined
  visibleLabels: VisibleLabel[]
  sectionsDisplayName: string
  sectionsDisplayNameSingular: string
}

export const appendTrendsChartOCElements = ({
  svg,
  tooltip,
  xScaleGroup,
  yScale,
  uniqueMonths,
  selectedTimePeriod,
  sectionsDisplayName,
  sectionsDisplayNameSingular,
  selectedFrequency,
  selectedSeverity,
  groupedByWeeks,
  filteredData,
  dataStartsAt,
  width,
  height,
  chartHeightStart,
  healthUnit,
  noData,
  offsetLeft,
  stripeWidth,
  setCustomDate,
  healthBorder,
  setRedirectToMode,
  setDrawerOpen,
  setMode,
  marginTop,
  marginBottom,
  getLineColor,
  maxYValue,
  maxValueY,
  max,
  getAreaStroke,
  pathStr,
  pinnedLabels,
  dataDates,
  data,
  chartData,
  visibleLabels
}: AppendTrendsChartOCElementsProps) => {
  const { recentValue, sla, status } = data
  const overflowValuePadding = recentValue > 0 ? 8 : recentValue < -90 ? 30 : recentValue < -80 ? 16 : 8
  const maxY = maxValueY === 0 ? maxYValue : max
  const rpYPercentage = recentValue === 0 ? 0 : healthUnit === 'percent' ? recentValue / 100 : recentValue / maxY / 2
  const recentPerformanceYPositionValue =
    rpYPercentage === 0
      ? height / 2 + overflowValuePadding
      : height / 2 - rpYPercentage * height + chartHeightStart - overflowValuePadding
  const areaStroke = getAreaStroke(chartData)
  const lineColorTop = getLineColor(chartData, 'top')
  const lineColorBottom = getLineColor(chartData, 'bottom')

  appendHoverPointCircles(svg)
  appendHoverPointLine({ svg, tooltip, height, chartHeightStart })
  appendRecentPerformanceLine({
    svg,
    width,
    chartHeightStart,
    status,
    recentPerformanceYPositionValue
  })
  appendFilledAreaStroke({ svg, areaStroke, pathStr })
  if (!noData) {
    appendHealthLines({ svg, height, chartHeightStart, healthBorder, lineColorTop, lineColorBottom })
  }
  appendSlaElementsOC({
    svg,
    sectionsDisplayName,
    sectionsDisplayNameSingular,
    selectedSeverity,
    width,
    height,
    healthBorder,
    sla,
    healthUnit,
    visibleLabels
  })
  appendStripesOC({
    svg,
    tooltip,
    uniqueMonths,
    xScaleGroup,
    height,
    marginTop,
    marginBottom,
    setDrawerOpen,
    setMode,
    selectedTimePeriod,
    selectedFrequency,
    filteredData,
    groupedByWeeks,
    setCustomDate,
    setRedirectToMode,
    offsetLeft,
    stripeWidth,
    dataStartsAt
  })
  appendCartesianGridHorizontalOC({ svg, width, height, chartHeightStart })
  appendXYAxisOC({
    svg,
    data,
    xScaleGroup,
    yScale,
    selectedTimePeriod,
    selectedFrequency,
    filteredData,
    dataStartsAt,
    height,
    healthUnit,
    noData
  })
  appendEventIndicators({
    svg,
    uniqueMonths,
    pinnedLabels,
    selectedTimePeriod,
    selectedFrequency,
    filteredData,
    groupedByWeeks,
    dataDates,
    height,
    stripeWidth,
    setDrawerOpen,
    setMode,
    offsetLeft,
    setCustomDate,
    setRedirectToMode,
    dataStartsAt,
    status: chartData?.status
  })
}
