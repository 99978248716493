import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { FormWrapper, LoadingWrapper } from './ZendeskSSO.styles'
import OnyxiaBigLogo from '../../components/svg/OnyxiaBigLogo'
import axios from '../../../lib/axios'
import { useNavigate } from 'react-router-dom'

const ZendeskSso = () => {
  const navigate = useNavigate()
  const [data, setData] = useState({
    token: '',
    location: ''
  })

  useEffect(() => {
    sendRequest()
  }, [])

  useEffect(() => {
    if (Object.keys(data).length && data.location && data.token) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.forms.jwtForm.submit()
    }
  }, [data])

  const sendRequest = async () => {
    try {
      const resp = await axios.post('/api/v1/zendesk/sso/auth')
      if (resp.status === 200) {
        const obj = {
          token: resp.data.jwt_token,
          location: resp.data.location
        }
        return setData(obj)
      }

      navigate('/dashboard')
    } catch (e) {
      navigate('/dashboard')
    }
  }

  return (
    <>
      <LoadingWrapper>
        <Box className="content">
          <Typography className="title"> Authenticating ...</Typography>
          <Typography className="desc"> One second while we direct you to the documentation.</Typography>
        </Box>
        <Box className="logo">
          <OnyxiaBigLogo />
        </Box>
      </LoadingWrapper>

      <FormWrapper>
        <form id="jwtForm" method="post" action={data.location}>
          <input id="jwtInput" type="hidden" name="jwt" value={data.token} />
          <input type="submit" value="Login" />
        </form>
      </FormWrapper>
    </>
  )
}

export default ZendeskSso
