import theme from 'theme'

const SidebarLibraryIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 10.9375C2.5 10.4197 2.91973 10 3.4375 10H5.3125C5.83027 10 6.25 10.4197 6.25 10.9375V16.5625C6.25 17.0803 5.83027 17.5 5.3125 17.5H3.4375C2.91973 17.5 2.5 17.0803 2.5 16.5625V10.9375Z"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 7.1875C8.125 6.66973 8.54473 6.25 9.0625 6.25H10.9375C11.4553 6.25 11.875 6.66973 11.875 7.1875V16.5625C11.875 17.0803 11.4553 17.5 10.9375 17.5H9.0625C8.54473 17.5 8.125 17.0803 8.125 16.5625V7.1875Z"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 3.4375C13.75 2.91973 14.1697 2.5 14.6875 2.5H16.5625C17.0803 2.5 17.5 2.91973 17.5 3.4375V16.5625C17.5 17.0803 17.0803 17.5 16.5625 17.5H14.6875C14.1697 17.5 13.75 17.0803 13.75 16.5625V3.4375Z"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SidebarLibraryIcon
