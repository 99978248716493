import { Avatar as MuiAvatar, IconButton as MuiIconButton } from '@mui/material'
import styled from '@emotion/styled/macro'
import theme from 'theme'

export const IconButton = styled(MuiIconButton)`
  border-radius: ${theme.attributes.borderRadius.circle};
  padding: 0;
  background: #debdd9;
`

export const Avatar = styled(MuiAvatar)<{ active?: boolean }>`
  width: 40px;
  height: 40px;
  background: ${({ active }) => (active ? theme.colors.primaryLight : '#debdd9')};
  color: ${({ active }) => (active ? theme.colors.white : '#3f293e')};
  text-transform: uppercase;
  font-family: ${theme.typography.fontFamily.primary};
  font-weight: ${theme.typography.fontWeight.bold};
  font-size: ${theme.typography.fontSize.xLarge};
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
`
