import theme from 'theme'

const SidebarHomeActiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.25 5.9375C1.25 5.07456 1.94956 4.375 2.8125 4.375H7.8125C8.67544 4.375 9.375 5.07456 9.375 5.9375V9.0625C9.375 9.92545 8.67544 10.625 7.8125 10.625H2.8125C1.94956 10.625 1.25 9.92544 1.25 9.0625V5.9375ZM11.25 7.1875C11.25 6.32456 11.9496 5.625 12.8125 5.625H17.1875C18.0504 5.625 18.75 6.32456 18.75 7.1875V14.0625C18.75 14.9254 18.0504 15.625 17.1875 15.625H12.8125C11.9496 15.625 11.25 14.9254 11.25 14.0625V7.1875ZM2.5 13.4375C2.5 12.5746 3.19956 11.875 4.0625 11.875H8.4375C9.30044 11.875 10 12.5746 10 13.4375V15.3125C10 16.1754 9.30044 16.875 8.4375 16.875H4.0625C3.19955 16.875 2.5 16.1754 2.5 15.3125V13.4375Z"
      fill={theme.colors.white}
    />
  </svg>
)

export default SidebarHomeActiveIcon
