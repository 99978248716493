import theme from 'theme'

const SecurityStackMapActiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.70369 1.32471C9.88867 1.2251 10.1113 1.2251 10.2963 1.32471L18.4213 5.69971C18.6238 5.80871 18.75 6.02007 18.75 6.25C18.75 6.47993 18.6238 6.69129 18.4213 6.80029L10.2963 11.1753C10.1113 11.2749 9.88867 11.2749 9.70369 11.1753L1.57869 6.80029C1.37624 6.69129 1.25 6.47993 1.25 6.25C1.25 6.02007 1.37624 5.80871 1.57869 5.69971L9.70369 1.32471Z"
      fill={theme.colors.white}
    />
    <path
      d="M2.72057 8.83484L9.11106 12.2759C9.66602 12.5747 10.334 12.5747 10.8889 12.2759L17.2794 8.83484L18.4213 9.4497C18.6238 9.55871 18.75 9.77007 18.75 9.99999C18.75 10.2299 18.6238 10.4413 18.4213 10.5503L10.2963 14.9253C10.1113 15.0249 9.88867 15.0249 9.70369 14.9253L1.57869 10.5503C1.37624 10.4413 1.25 10.2299 1.25 9.99999C1.25 9.77007 1.37624 9.55871 1.57869 9.4497L2.72057 8.83484Z"
      fill={theme.colors.white}
    />
    <path
      d="M9.11106 16.0259L2.72057 12.5848L1.57869 13.1997C1.37624 13.3087 1.25 13.5201 1.25 13.75C1.25 13.9799 1.37624 14.1913 1.57869 14.3003L9.70369 18.6753C9.88867 18.7749 10.1113 18.7749 10.2963 18.6753L18.4213 14.3003C18.6238 14.1913 18.75 13.9799 18.75 13.75C18.75 13.5201 18.6238 13.3087 18.4213 13.1997L17.2794 12.5848L10.8889 16.0259C10.334 16.3247 9.66602 16.3247 9.11106 16.0259Z"
      fill={theme.colors.white}
    />
  </svg>
)

export default SecurityStackMapActiveIcon
