import theme from 'theme'

const SidebarLibraryActiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3125 1.875C14.4496 1.875 13.75 2.57456 13.75 3.4375V16.5625C13.75 17.4254 14.4496 18.125 15.3125 18.125H15.9375C16.8004 18.125 17.5 17.4254 17.5 16.5625V3.4375C17.5 2.57456 16.8004 1.875 15.9375 1.875H15.3125Z"
      fill={theme.colors.white}
    />
    <path
      d="M8.125 7.1875C8.125 6.32455 8.82456 5.625 9.6875 5.625H10.3125C11.1754 5.625 11.875 6.32456 11.875 7.1875V16.5625C11.875 17.4254 11.1754 18.125 10.3125 18.125H9.6875C8.82456 18.125 8.125 17.4254 8.125 16.5625V7.1875Z"
      fill={theme.colors.white}
    />
    <path
      d="M2.5 10.9375C2.5 10.0746 3.19956 9.375 4.0625 9.375H4.6875C5.55044 9.375 6.25 10.0746 6.25 10.9375V16.5625C6.25 17.4254 5.55044 18.125 4.6875 18.125H4.0625C3.19956 18.125 2.5 17.4254 2.5 16.5625V10.9375Z"
      fill={theme.colors.white}
    />
  </svg>
)

export default SidebarLibraryActiveIcon
