import { FC, SVGProps } from 'react'
import theme from 'theme'

type Props = SVGProps<SVGSVGElement>

const InsightsActiveIcon: FC<Props> = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 0.625C6.20304 0.625 3.125 3.70304 3.125 7.5C3.125 10.0423 4.50526 12.2615 6.5543 13.4504C7.12551 13.7818 7.48321 14.2903 7.4994 14.8021C7.50833 15.0844 7.70547 15.3257 7.98033 15.3906C8.27315 15.4598 8.57154 15.5143 8.87466 15.5532C9.14433 15.5879 9.37515 15.3719 9.37515 15.1V11.2158C9.10908 11.1864 8.84836 11.1383 8.59436 11.073C8.26008 10.9869 8.05884 10.6462 8.14488 10.3119C8.23092 9.97761 8.57165 9.77637 8.90594 9.86241C9.25495 9.95224 9.62147 10.0002 10.0002 10.0002C10.3788 10.0002 10.7454 9.95224 11.0944 9.86241C11.4286 9.77637 11.7694 9.97761 11.8554 10.3119C11.9415 10.6462 11.7402 10.9869 11.4059 11.073C11.1519 11.1383 10.8912 11.1864 10.6252 11.2158V15.0999C10.6252 15.3718 10.856 15.5879 11.1257 15.5532C11.4287 15.5143 11.727 15.4598 12.0197 15.3906C12.2945 15.3257 12.4917 15.0844 12.5006 14.8021C12.5168 14.2903 12.8745 13.7818 13.4457 13.4504C15.4947 12.2615 16.875 10.0423 16.875 7.5C16.875 3.70304 13.797 0.625 10 0.625Z"
      fill={theme.colors.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.51095 16.583C7.57528 16.2439 7.90235 16.0211 8.24148 16.0855C8.81062 16.1934 9.39841 16.25 10 16.25C10.6016 16.25 11.1894 16.1934 11.7585 16.0855C12.0977 16.0211 12.4247 16.2439 12.4891 16.583C12.5534 16.9222 12.3306 17.2492 11.9915 17.3136C11.3459 17.436 10.6802 17.5 10 17.5C9.31985 17.5 8.65407 17.436 8.00852 17.3136C7.66939 17.2492 7.44662 16.9222 7.51095 16.583Z"
      fill={theme.colors.white}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.12839 18.62C8.16431 18.2767 8.47172 18.0275 8.81503 18.0634C9.20426 18.1041 9.59958 18.125 10 18.125C10.4004 18.125 10.7957 18.1041 11.185 18.0634C11.5283 18.0275 11.8357 18.2767 11.8716 18.62C11.9075 18.9633 11.6583 19.2707 11.315 19.3066C10.8827 19.3518 10.4439 19.375 10 19.375C9.55607 19.375 9.11733 19.3518 8.68497 19.3066C8.34167 19.2707 8.09248 18.9633 8.12839 18.62Z"
      fill={theme.colors.white}
    />
  </svg>
)

export default InsightsActiveIcon
