import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, SelectChangeEvent, Typography, useMediaQuery } from '@mui/material'
import axios from '../../../../../../lib/axios'
import dayjs from 'dayjs'
import * as d3 from 'd3'
import theme from 'theme'

/* Utils */
import {
  ChartData,
  ChartDataExtended,
  ChartDataValue,
  ConfiguredCPI,
  CPIFrequency,
  CPILibraryItem,
  CPIManifest,
  CPIManifestUIVisualizationParamSection,
  CPISeverity,
  CPITimePeriod,
  CustomLabelCountI,
  CustomLabelDate,
  CustomLabelsDataPayload,
  CustomLabelsListI,
  CustomTrendsDataPayload,
  D3Position,
  DataStatus,
  DateRangeI,
  IntegrationInstance,
  Label,
  LabelCount,
  LabelCountData,
  PredictionChartData,
  PredictionData,
  SystemEventName,
  TrendsDataPayload,
  VisibleLabel
} from '../../../../../../models'
import { DEFAULT_TIMER, INITIALIZING_TIMER, RECALCULATING_TIMER } from '../../../library.constants'
import { checkIsGreen, dateFormat, getHealthDirection } from '../../../../../../lib/utils'
import {
  addStartEndWeekly,
  convertDateToUTC,
  extractMonth,
  filterData,
  findNearestXIndex,
  findUniqueMonths,
  findUniqueMonthsInitializing,
  findVisibleLabelsXPosition,
  findVisibleLabelsXPositionMonthly,
  getChartHeight,
  // getChartWidth,
  getPathAndPositions,
  getPathAndPositionsOC,
  getPayloadTimePeriod,
  getPayloadTrendsChart,
  getSingularSectionsName,
  getUtcDateAndTime,
  groupByWeeks,
  payloadToEnumTimePeriod
} from '../../../utils'
import {
  appendTrendsChartElements,
  appendTrendsChartOCElements,
  chartHeightStart,
  getAreaFill,
  getAreaStroke,
  getDataStartsAt,
  getLineColor,
  getYLine,
  onMouseMove,
  xAxisHeight
} from './chart-utils'
import { raiseElements } from './chart-components'
import { removeElements } from './chart-components-oc'

/* Components */
import {
  CPIChangeLabelIcon,
  CustomLabelIcon,
  InstanceAddedLabelIcon,
  InstanceRemovedLabelIcon,
  SLAChangeLabelIcon
} from '../../../../../components/svg'
import { ChartWrapper } from './trendsTab.styles'
import TrendsChart from './TrendsChart'
import TrendsChartDescription from './sections/TrendsChartDescription'
import LabelsDrawer from './components/LabelsDrawer'
import TrendsChartSelectField from './components/TrendsChartSelectField'

interface Props {
  fetchPredictionData: (cpiName: string, severity?: CPISeverity, dataSourceName?: string) => Promise<PredictionData[]>
  hasPredictionsEnabled: boolean
  isMediumHeight: boolean
  manifestData: CPIManifest
  chartData: ChartData | null
  setChartData: Dispatch<SetStateAction<ChartData | null>>
  setSelectedSeverity: Dispatch<SetStateAction<CPISeverity>>
  setSelectedTimePeriod: Dispatch<SetStateAction<CPITimePeriod>>
  setSelectedFrequency: Dispatch<SetStateAction<CPIFrequency>>
  setDataSourceValue: Dispatch<SetStateAction<string>>
  selectedTimePeriod: CPITimePeriod
  selectedSeverity: CPISeverity
  selectedFrequency: CPIFrequency
  userConfiguredIntegrations: IntegrationInstance[]
  cpiConfiguration: ConfiguredCPI | null
  cpiSupportedAndUserConfiguredList: IntegrationInstance[]
  timePeriodValues: Array<CPITimePeriod | string>
  frequencyValues: Array<CPIFrequency>
  dataSourceOptions: IntegrationInstance[]
  dataSourceValue: string
  setEmptyInfoTooltipOpen: Dispatch<SetStateAction<boolean>>
  emptyInfoTooltipOpen: boolean
  selectedType: string
  handleEmptyInfoClick: () => void
  currentSeverityStatus: 'healthy' | 'problematic'
  defaultValues: Record<string, unknown>
  fetchingInterval: number
  cpi: CPILibraryItem | null
  setFetchingInterval: Dispatch<SetStateAction<number>>
  setSelectedType: Dispatch<SetStateAction<string>>
  setHealthDirection: Dispatch<SetStateAction<string>>
  setCriticalSla: Dispatch<SetStateAction<number>>
  setCurrentSeverityStatus: Dispatch<SetStateAction<'healthy' | 'problematic'>>
  selectedDataSourceIds: string[]
  setSelectedDataSourceIds: Dispatch<SetStateAction<string[]>>
  stripes?: number | null
  setStripes: Dispatch<SetStateAction<number | null>>
  customTimePeriodValue: string
  setCustomTimePeriodValue: Dispatch<SetStateAction<string>>
  predictionModalOpen: boolean
  handlePredictionModalOpen: () => void
  redirectToMode: string
  setRedirectToMode: Dispatch<SetStateAction<string>>
  labelDrawerOpen: boolean
  setLabelDrawerOpen: Dispatch<SetStateAction<boolean>>
  mode: string
  setMode: Dispatch<SetStateAction<string>>
  predictionModelAvailable: boolean
  refetchPredictionData: ({
    thisCpiName,
    severity,
    selectedSources,
    timePeriod
  }: {
    thisCpiName: string
    severity?: CPISeverity
    selectedSources?: string[]
    timePeriod?: CPITimePeriod
  }) => Promise<ChartData | null>
  daysUntilDataAvailable: number
  setPredictionData: Dispatch<SetStateAction<PredictionChartData | null>>
  renderingChart: boolean
  setRenderingChart: Dispatch<SetStateAction<boolean>>
  setShowMessage: Dispatch<SetStateAction<boolean>>
}

const TrendsTab: FC<Props> = ({
  fetchPredictionData,
  cpi,
  setPredictionData,
  setHealthDirection,
  setFetchingInterval,
  fetchingInterval,
  setCriticalSla,
  isMediumHeight,
  manifestData,
  chartData,
  setChartData,
  setSelectedSeverity,
  setSelectedTimePeriod,
  setSelectedFrequency,
  setDataSourceValue,
  selectedTimePeriod,
  selectedSeverity,
  selectedFrequency,
  userConfiguredIntegrations,
  cpiConfiguration,
  selectedType,
  cpiSupportedAndUserConfiguredList,
  timePeriodValues,
  frequencyValues,
  dataSourceOptions,
  dataSourceValue,
  setEmptyInfoTooltipOpen,
  emptyInfoTooltipOpen,
  handleEmptyInfoClick,
  setSelectedType,
  currentSeverityStatus,
  setCurrentSeverityStatus,
  defaultValues,
  selectedDataSourceIds,
  setSelectedDataSourceIds,
  stripes,
  setStripes,
  customTimePeriodValue,
  setCustomTimePeriodValue,
  predictionModalOpen,
  handlePredictionModalOpen,
  redirectToMode,
  setRedirectToMode,
  labelDrawerOpen,
  setLabelDrawerOpen,
  mode,
  setMode,
  hasPredictionsEnabled,
  predictionModelAvailable,
  refetchPredictionData,
  daysUntilDataAvailable,
  renderingChart,
  setRenderingChart,
  setShowMessage
}) => {
  const location = useLocation()
  const descriptionWrapperRef = useRef<HTMLDivElement | null>(null)
  const [editLabel, setEditLabel] = useState({} as Label)
  const [customLabels, setCustomLabels] = useState({} as CustomLabelsListI)
  const [filteredLabels, setFilteredLabels] = useState({} as CustomLabelsListI)
  const [customLabelsCount, setCustomLabelsCount] = useState({} as CustomLabelCountI)
  const [customDate, setCustomDate] = useState<CustomLabelDate>({
    date: '',
    start: '',
    end: ''
  })
  const [appliedFilter, setAppliedFilter] = useState({
    startDate: '',
    endDate: '',
    types: []
  })
  const [loading, setLoading] = useState(false)
  const [labelsCount, setLabelsCount] = useState<LabelCountData | null>(null)
  const [currentPositions, setCurrentPositions] = useState<D3Position[]>([])
  const [currentUniqueMonths, setCurrentUniqueMonths] = useState<string[]>([])

  const [chartDateRange, setChartDateRange] = useState({} as DateRangeI)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const svgRef = useRef<SVGSVGElement | null>(null)
  const [maxValueY, setMaxValueY] = useState(100)
  const isSmallHeight = useMediaQuery('(max-height:800px)')
  const isBigHeight = useMediaQuery('(min-height:901px)')
  const isSmall = useMediaQuery('(max-width:1200px)')
  const isLaptop = useMediaQuery('(max-width:1280px)')
  const isLargeScreen = useMediaQuery('(max-width:1570px)')
  const isExtraLargeScreen = useMediaQuery('(max-width:1770px)')
  const [width, setWidth] = useState(
    (descriptionWrapperRef as any)?.current?.clientWidth ||
      (!isSmall && !isLaptop && !isLargeScreen && !isExtraLargeScreen)
      ? 1638
      : window.innerWidth - 120
  )
  // const [width, setWidth] = useState(
  //   (descriptionWrapperRef as any)?.current?.clientWidth ||
  //     getChartWidth(isSmall, isLaptop, isLargeScreen, isExtraLargeScreen)
  // )
  const margin = { top: 20, right: 0, bottom: 40, left: 0 }
  const [height, setHeight] = useState(
    getChartHeight(isSmallHeight, isMediumHeight, isBigHeight) - margin.top - margin.bottom
  )
  const [visibleLabels, setVisibleLabels] = useState<VisibleLabel[]>([])
  const [initialLoad, setInitialLoad] = useState(false)

  const fetchTrendsData = useCallback(
    async (payload: TrendsDataPayload, stripes?: number | null) => {
      if (!cpi) return

      let severities: any = []
      const userInput = manifestData.ui.trend_chart.user_input
      if (userInput.formula_params[0]?.sections.length > 0) {
        severities = userInput.formula_params[0]?.sections
      } else if (userInput.visualization_param.sections.length > 1) {
        severities = userInput.visualization_param.sections
      }

      const newSection = severities.find((item: { display_name: CPISeverity }) => item.display_name === payload.section)
      if (newSection) payload.section = newSection.name

      try {
        const res = await axios.post(`/api/v3/cpis/${cpi.name}/data`, payload)

        if (res.status === 201) {
          const { data }: { data: ChartData } = res.data
          setShowMessage(data.status === DataStatus.Recalculating)

          if (data.status === DataStatus.Initializing && !data.values) {
            setFetchingInterval(INITIALIZING_TIMER)
            const userInput = manifestData.ui.trend_chart.user_input

            const sectionSla = userInput.visualization_param.sections.find((n) => n.name === selectedSeverity)
            const sla =
              sectionSla && sectionSla.value ? sectionSla.value : userInput.visualization_param.identical_value ?? 0
            const stubValues: ChartDataValue[] = []
            let date = dayjs().format('YYYY-MM-DD')
            Array.from(Array(3).keys()).forEach((n) => {
              let d = date
              if (n !== 0) {
                const c = dayjs(date).subtract(1, 'month')
                d = dayjs(c).format('MM-DD-YYYY')
              }
              stubValues.push({
                xValue: n === 0 ? date : dayjs(d).format('YYYY-MM-DD'),
                yValue: 0,
                denominator: 0,
                numerator: 0
              })
              date = dayjs(d).format('MM-DD-YYYY')
            })
            const today = new Date().toISOString()
            const stubInitializingData: ChartData = {
              recentValue: 0,
              recentDate: today,
              status: DataStatus.Initializing,
              zoneColor: 'green',
              sla,
              updatedAt: today,
              values: stubValues
            }
            setChartData(stubInitializingData)

            return
          }

          const normalizedValues = data.values.map((value) => ({
            ...value,
            yValue: isNaN(value.yValue) ? 0 : value.yValue
          }))
          const normalizedData = {
            ...data,
            values: normalizedValues
          }
          setChartData(normalizedData)
          setCriticalSla(data.sla)

          if (data.status === DataStatus.Ready) {
            setFetchingInterval(DEFAULT_TIMER)
          }
          if (data.status === DataStatus.Recalculating) {
            setFetchingInterval(RECALCULATING_TIMER)
          }
          if (data.status === DataStatus.Initializing) {
            setFetchingInterval(INITIALIZING_TIMER)
          }

          if (data) {
            const { sla, recentValue, zoneColor } = data
            const currentSeverity = payload.section

            if (!!currentSeverity && !!data && data.values?.length > 0) {
              const healthDirection = getHealthDirection(zoneColor, data.recentValue, sla)
              const isGreen = checkIsGreen(healthDirection, recentValue, sla)
              setHealthDirection(healthDirection)

              if (isGreen) {
                setCurrentSeverityStatus('healthy')
              } else {
                setCurrentSeverityStatus('problematic')
              }
            }
          }

          return data
        }
      } catch (e) {
        console.error(e)
      } finally {
        setStripes(stripes ?? null)
      }
    },
    [cpi]
  )

  useEffect(() => {
    if (labelDrawerOpen) {
      const tooltip = d3.select('.data-point-tooltip')
      tooltip.style('display', 'none')
    }
  }, [labelDrawerOpen])

  useEffect(() => {
    fetchInitialLabels().catch((e) => e)
  }, [cpi])

  useEffect(() => {
    if (!cpi || (selectedDataSourceIds.length === 1 && selectedDataSourceIds[0] === '')) return

    const timePeriod = getPayloadTimePeriod(selectedTimePeriod)
    const payload: TrendsDataPayload = {
      section: selectedSeverity,
      integration_ids: selectedDataSourceIds[0] === '' ? [''] : selectedDataSourceIds, // configured integration id
      frequency: selectedFrequency
    }
    if (customTimePeriodValue) {
      const { dateFrom, dateTo } = extractMonth(customTimePeriodValue)
      const dateToTemp = new Date(dateTo).getTime()
      const today = new Date().getTime()
      const dateToFormatted = dateToTemp > today ? dayjs().format('YYYY-MM-DD') : dateTo
      payload.dateFrom = dateFrom
      payload.dateTo = dateToFormatted
    } else {
      payload.timePeriod = timePeriod
    }

    fetchTrendsData(payload, stripes)
      .catch((e) => e)
      .finally(() => setInitialLoad(true))
  }, [cpi, stripes, selectedDataSourceIds])

  useEffect(() => {
    if (!initialLoad || !cpi || (selectedDataSourceIds.length === 1 && selectedDataSourceIds[0] === '')) return

    const timePeriod = getPayloadTimePeriod(selectedTimePeriod)
    const payload: TrendsDataPayload = {
      section: selectedSeverity,
      integration_ids: selectedDataSourceIds[0] === '' ? [''] : selectedDataSourceIds, // configured integration id
      frequency: selectedFrequency
    }
    if (customTimePeriodValue) {
      const { dateFrom, dateTo } = extractMonth(customTimePeriodValue)
      const dateToTemp = new Date(dateTo).getTime()
      const today = new Date().getTime()
      const dateToFormatted = dateToTemp > today ? dayjs().format('YYYY-MM-DD') : dateTo

      payload.dateFrom = dateFrom
      payload.dateTo = dateToFormatted
    } else {
      payload.timePeriod = timePeriod
    }

    const refetchData = setInterval(() => {
      fetchTrendsData(payload, stripes).catch((e) => e)
    }, fetchingInterval)

    return () => {
      clearInterval(refetchData)
    }
  }, [
    cpi,
    stripes,
    customTimePeriodValue,
    selectedDataSourceIds,
    selectedFrequency,
    selectedSeverity,
    selectedTimePeriod,
    fetchingInterval,
    initialLoad
  ])

  useEffect(() => {
    if (!labelDrawerOpen) {
      setMode('show-0')
      setEditLabel({} as any)
      setCustomDate({
        date: '',
        start: '',
        end: ''
      })
      setAppliedFilter({
        startDate: '',
        endDate: '',
        types: []
      })
    }
  }, [labelDrawerOpen])

  useEffect(() => {
    if (labelDrawerOpen) {
      setLoading(true)
      updateLabels()
    }
  }, [labelDrawerOpen, selectedFrequency, selectedTimePeriod])

  const buildTrendsChart = useCallback(
    (data: ChartData, yValues: number[]) => {
      // setRenderingChart(true)
      const isNoSeveritiesCpi =
        !manifestData?.ui.trend_chart.user_input.formula_params ||
        (manifestData?.ui.trend_chart.user_input.visualization_param.identical_section_values &&
          manifestData?.ui.trend_chart.user_input.visualization_param.sections.length < 2)
      const offsetLeft = containerRef?.current?.offsetLeft || 0
      const healthUnit = manifestData?.ui.trend_chart.user_input.visualization_param.unit
      const tooltipUnit = manifestData?.ui.trend_chart.user_input.visualization_param.tooltip_unit
      const dataFirstValue = getUtcDateAndTime(data.values[0].xValue || '').labelFormatDate

      const svg = d3.select(svgRef.current)
      const tooltip = d3.select('.data-point-tooltip')
      const everything = svg.selectAll('*')
      everything.remove()
      // svg.attr('visibility', 'hidden')

      const filteredData = data.values
      let groupedByWeeks: ChartDataExtended | null = null
      if (selectedTimePeriod === CPITimePeriod.Month) {
        groupedByWeeks = groupByWeeks(data)
      }
      if (selectedFrequency === CPIFrequency.Weekly && selectedTimePeriod !== CPITimePeriod.Month) {
        groupedByWeeks = addStartEndWeekly(data)
      }
      const dataStartsAt = getDataStartsAt(dataFirstValue, groupedByWeeks, selectedFrequency)

      const maxYScaleValue = Number(d3.max(yValues))
      const getYAxisDomain = (): [number, number] => {
        const defaultPercent: [number, number] = [0, 100]
        const nonPercentRange: [number, number] = [0, maxYScaleValue * 2]

        if (healthUnit === 'percent') {
          return defaultPercent
        }
        if (maxYScaleValue === 0 || data.status !== DataStatus.Ready) {
          return [0, 30]
        }
        return nonPercentRange
      }
      const yAxisDomain = getYAxisDomain()
      let uniqueMonths = findUniqueMonths({
        filteredData,
        groupedByWeeks,
        selectedTimePeriod
      })
      if (data.status === DataStatus.Initializing) {
        uniqueMonths = findUniqueMonthsInitializing(filteredData, selectedTimePeriod, data)
      }
      setCurrentUniqueMonths(uniqueMonths)
      const stripeWidth = width / uniqueMonths.length
      const notReadyMiddle = yAxisDomain[1] / 10
      const positionsData: ChartDataValue[] =
        data.status === DataStatus.Ready
          ? filteredData
          : filteredData.map((n) => ({
              ...n,
              yValue: notReadyMiddle
            }))

      const [pathStr, positions] = getPathAndPositions({
        uniqueMonths,
        stripeWidth,
        height,
        filteredData: positionsData,
        groupedByWeeks,
        selectedTimePeriod,
        selectedFrequency,
        yAxisDomain,
        dataStatus: data.status,
        chartHeightStart
      })
      setCurrentPositions(positions)

      if (positions.length > 0) {
        if (selectedFrequency === CPIFrequency.Daily) {
          setChartDateRange({
            minDate: positions[0].date || '',
            maxDate: positions[positions.length - 1].date || ''
          })
        } else if (selectedFrequency === CPIFrequency.Weekly && groupedByWeeks) {
          setChartDateRange({
            minDate: groupedByWeeks.values[0].start,
            maxDate: groupedByWeeks.values[groupedByWeeks.values.length - 1].start
          })
        } else {
          setChartDateRange({
            minDate: `${uniqueMonths[0]}-01`,
            maxDate: dayjs().format(dateFormat.customLabel)
          })
        }
      }

      const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([0, width]).padding(0)
      const yScale = d3.scaleLinear().domain(yAxisDomain).range([height, chartHeightStart]).nice()

      const yLine = getYLine(chartData, maxValueY)
      const healthBorder = yScale(yLine)

      let dataDates: LabelCount[] = []
      if (labelsCount && labelsCount.labels_counts.length > 0) {
        dataDates = labelsCount.labels_counts.map((label) => ({ ...label, date: label.date.slice(0, 10) }))
      }
      const pinnedLabels = labelsCount?.pinned_labels

      let thisVisibleLabels = []

      if (selectedTimePeriod !== CPITimePeriod.Week) {
        const scaledLabels = findVisibleLabelsXPositionMonthly(
          labelsCount?.pinned_labels || [],
          width,
          uniqueMonths,
          selectedTimePeriod
        )
        setVisibleLabels(scaledLabels)
        thisVisibleLabels = scaledLabels
      } else {
        const scaledLabels = findVisibleLabelsXPosition(labelsCount?.pinned_labels || [], positions, width)
        setVisibleLabels(scaledLabels)
        thisVisibleLabels = scaledLabels
      }

      appendTrendsChartElements({
        svg,
        tooltip,
        xScaleGroup,
        sectionsDisplayName: manifestData?.ui.trend_chart.user_input.visualization_param.sections_display_name,
        sectionsDisplayNameSingular:
          manifestData?.ui.trend_chart.user_input.visualization_param.sections_singular_display_name,
        isNoSeveritiesCpi,
        yScale,
        positions,
        pathStr,
        uniqueMonths,
        filteredData,
        groupedByWeeks,
        data,
        pinnedLabels,
        dataDates,
        selectedTimePeriod,
        selectedFrequency,
        selectedSeverity,
        width,
        height,
        chartHeightStart,
        stripeWidth,
        healthUnit,
        healthBorder,
        maxYValue: yAxisDomain[1],
        maxYScaleValue,
        marginTop: margin.top,
        marginBottom: margin.bottom,
        offsetLeft,
        dataStartsAt,
        getAreaFill,
        getAreaStroke,
        getLineColor,
        setDrawerOpen: setLabelDrawerOpen,
        setMode,
        setCustomDate,
        setRedirectToMode,
        chartData,
        visibleLabels: thisVisibleLabels
      })

      /*
       * Mouse Move for data point hover tooltip
       * */
      svg.on('mousemove', (event) => {
        if (data.status !== DataStatus.Ready || !positions.length) return

        const [mouseX, mouseY] = d3.pointer(event)
        const dataPoint = findNearestXIndex(positions, mouseX)
        const barHeight = height + margin.top + margin.bottom
        const diff = barHeight - xAxisHeight
        const isHoveringXAxis = mouseY >= diff
        const matchingDataPoint =
          selectedFrequency === CPIFrequency.Weekly && groupedByWeeks
            ? groupedByWeeks.values[dataPoint?.index || 0]
            : filteredData[dataPoint?.index || 0]

        if (mouseX > width || mouseY > height || mouseY < height) {
          const addCustomEventButton = svg.select('g.add-event-button')
          addCustomEventButton.style('display', 'block')
        }

        if (!dataPoint) return
        onMouseMove({
          cpi: manifestData.name,
          svg,
          tooltip,
          event,
          dataPoint,
          d: matchingDataPoint,
          selectedTimePeriod,
          selectedFrequency,
          selectedSeverity,
          stripeWidth,
          isHoveringXAxis,
          healthUnit,
          tooltipUnit,
          isNoSeveritiesCpi,
          dataStartsAt,
          mouseX,
          mouseY
        })
      })

      raiseElements(svg)
      // setTimeout(() => {
      //   svg.attr('visibility', 'visible')
      // setRenderingChart(false)
      // }, 700)
    },
    [
      manifestData,
      width,
      height,
      margin,
      customLabels,
      labelsCount,
      labelDrawerOpen,
      selectedTimePeriod,
      selectedFrequency,
      selectedSeverity
    ]
  )

  const buildOpenClosedChart = (chartData: ChartData, timeRange: number, noData: boolean) => {
    setRenderingChart(true)
    const tooltipUnit = manifestData?.ui.trend_chart.user_input.visualization_param.tooltip_unit
    const healthUnit = manifestData?.ui.trend_chart.user_input.visualization_param.unit
    const dataStartsAt = dayjs(chartData.values[0].xValue || '').format('YYYY-MM-DD')
    const offsetLeft = containerRef?.current?.offsetLeft || 0
    const max = Math.max(...chartData.values.map((n) => Math.abs(n.yValue)))

    const svg = d3.select(svgRef.current)
    const tooltip = d3.select('.data-point-tooltip')
    const everything = svg.selectAll('*')
    everything.remove()
    svg.attr('visibility', 'hidden')

    const data: ChartData = {
      ...chartData,
      values: chartData.values.map((n) => {
        let d = n.xValue
        if (typeof d !== 'string') {
          d = getUtcDateAndTime(n.xValue).formattedDate
        }

        return {
          ...n,
          xValue: dayjs(d).format(dateFormat.customLabel)
        }
      })
    }
    const filteredData = filterData(timeRange, data)
    let groupedByWeeks: ChartDataExtended | null = null
    if (selectedTimePeriod === CPITimePeriod.Month) {
      groupedByWeeks = groupByWeeks(data)
    }
    if (selectedFrequency === CPIFrequency.Weekly && selectedTimePeriod !== CPITimePeriod.Month) {
      groupedByWeeks = addStartEndWeekly(data)
    }

    const uniqueMonths = findUniqueMonths({
      filteredData: data.values,
      selectedTimePeriod,
      groupedByWeeks
    })
    const stripeWidth = width / uniqueMonths.length
    const yDomain = max === 0 ? [-100, 100] : [-max, max]
    const maxYValue = yDomain[1]

    const [pathStr, positions] = getPathAndPositionsOC({
      uniqueMonths,
      stripeWidth,
      height,
      filteredData: chartData.values,
      groupedByWeeks,
      selectedTimePeriod,
      selectedFrequency,
      yAxisDomain: yDomain as [number, number],
      isReady: chartData.status === DataStatus.Ready,
      chartHeightStart
    })

    const yScale = d3.scaleLinear().domain(yDomain).range([height, chartHeightStart]).nice()
    const xScaleGroup = d3.scaleBand().domain(uniqueMonths).range([0, width]).padding(0)
    const yLine = getYLine(chartData, maxValueY)
    const healthBorder = yScale(yLine)

    let dataDates: LabelCount[] = []
    if (labelsCount && labelsCount.labels_counts.length > 0) {
      dataDates = labelsCount.labels_counts.map((label) => ({ ...label, date: label.date.slice(0, 10) }))
    }
    const pinnedLabels = labelsCount?.pinned_labels

    /*
     * Mouse Move for data point tooltip
     * */
    svg.on('mousemove', (event) => {
      if (chartData.status !== DataStatus.Ready || !positions.length) return

      const [mouseX, mouseY] = d3.pointer(event)
      const dataPoint = findNearestXIndex(positions, mouseX)
      const barHeight = height + margin.top + margin.bottom
      const diff = barHeight - xAxisHeight
      const isHoveringXAxis = mouseY >= diff
      const matchingDataPoint =
        selectedFrequency === CPIFrequency.Weekly && groupedByWeeks
          ? groupedByWeeks.values[dataPoint?.index || 0]
          : filteredData[dataPoint?.index || 0]

      if (mouseX > width || mouseY > height || mouseY < height) {
        const addCustomEventButton = svg.select('g.add-event-button')
        addCustomEventButton.style('display', 'block')
      }

      if (!dataPoint || noData) return
      onMouseMove({
        svg,
        tooltip,
        event,
        dataPoint,
        d: matchingDataPoint,
        selectedTimePeriod,
        selectedFrequency,
        selectedSeverity,
        stripeWidth,
        isHoveringXAxis,
        healthUnit,
        tooltipUnit,
        isNoSeveritiesCpi: false,
        dataStartsAt,
        ocTooltip: true,
        mouseX,
        mouseY
      })
    })

    let thisVisibleLabels = []

    if (selectedTimePeriod !== CPITimePeriod.Week) {
      const scaledLabels = findVisibleLabelsXPositionMonthly(
        labelsCount?.pinned_labels || [],
        width,
        uniqueMonths,
        selectedTimePeriod
      )
      setVisibleLabels(scaledLabels)
      thisVisibleLabels = scaledLabels
    } else {
      const scaledLabels = findVisibleLabelsXPosition(
        labelsCount?.pinned_labels || [],
        positions.map((n) => ({
          ...n,
          date: dayjs(n.date).format(dateFormat.customLabel)
        })),
        width
      )
      setVisibleLabels(scaledLabels)
      thisVisibleLabels = scaledLabels
    }

    appendTrendsChartOCElements({
      svg,
      data,
      tooltip,
      xScaleGroup,
      sectionsDisplayName: manifestData?.ui.trend_chart.user_input.visualization_param.sections_display_name,
      sectionsDisplayNameSingular:
        manifestData?.ui.trend_chart.user_input.visualization_param.sections_singular_display_name,
      yScale,
      uniqueMonths,
      selectedTimePeriod,
      selectedFrequency,
      selectedSeverity,
      healthBorder,
      filteredData,
      dataStartsAt,
      width,
      height,
      chartHeightStart,
      healthUnit,
      noData,
      offsetLeft,
      stripeWidth,
      setCustomDate,
      setRedirectToMode,
      setDrawerOpen: setLabelDrawerOpen,
      setMode,
      marginTop: margin.top,
      marginBottom: margin.bottom,
      getLineColor,
      maxYValue,
      maxValueY,
      max,
      getAreaStroke,
      pathStr,
      pinnedLabels,
      dataDates,
      groupedByWeeks,
      chartData,
      visibleLabels: thisVisibleLabels
    })

    if (noData) {
      removeElements(svg, tooltip)
    } else {
      raiseElements(svg)
    }
    setTimeout(() => {
      svg.attr('visibility', 'visible')
      setRenderingChart(false)
    }, 700)
  }

  useEffect(() => {
    if (!chartData || !manifestData) return

    const dataMaxValue = Math.max(...chartData.values.map((e: any) => (isNaN(e.yValue) ? 0 : e.yValue)))
    setMaxValueY(dataMaxValue)

    if (manifestData.display_name === 'CPI-007') {
      if (chartData.values.length === 0) return

      setMaxValueY(dataMaxValue)
      let noData = false
      if (!chartData.values) {
        noData = true
      }
      const months =
        stripes !== null && typeof stripes !== 'undefined'
          ? stripes
          : selectedTimePeriod === CPITimePeriod.PastYear
          ? 12
          : parseInt(selectedTimePeriod.split('')[0]) + 1
      const parseTime = d3.timeParse('%Y-%m-%dT%H:%M:%S.%LZ')
      chartData.values.forEach((d) => {
        const xVal = parseTime(d.xValue) as Date
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (xVal) d.xValue = xVal
      })

      buildOpenClosedChart(chartData, months, noData)
    } else {
      chartData.values = chartData.values.map((e) => {
        const num = isNaN(e.yValue) ? 0 : e.yValue

        // return { ...e, yValue: dataMaxValue === 0 ? 0 : num, xValue: dayjs(e.xValue).format('YYYY-MM-DD') }
        return {
          ...e,
          yValue: dataMaxValue === 0 ? 0 : num,
          xValue: dayjs(convertDateToUTC(new Date(e.xValue))).format('YYYY-MM-DD')
        }
      })

      const yValues = chartData.values.map((d) => (isNaN(d.yValue) ? 0 : d.yValue))

      buildTrendsChart(chartData, yValues)
      setRenderingChart(false)
    }
  }, [chartData, customLabels, customLabelsCount, location])

  const filterLabelsData = (filters: any) => {
    if (!filters) {
      return setAppliedFilter({
        startDate: '',
        endDate: '',
        types: []
      })
    }

    const filterData = {} as CustomLabelsListI
    Object.keys(customLabels).forEach((item) => {
      if (item >= filters.startDate && item <= filters.endDate) {
        if (filters.types.length) {
          const list = customLabels[item].filter((i: { action: string }) => filters.types.includes(i.action))
          if (list.length) filterData[item] = list
        } else {
          filterData[item] = customLabels[item]
        }
      }
    })
    setAppliedFilter(filters)
    setFilteredLabels(filterData)
  }

  const fetchCustomLabelsCount = useCallback(
    async (payload: CustomLabelsDataPayload) => {
      try {
        const resp = await axios.post(`/api/v3/labels/cpis/${cpi?.name}/count?fetch-labels-count`, payload)
        setCustomLabelsCount(resp.data.data)
        setLabelsCount(resp.data.data)

        if (selectedTimePeriod !== CPITimePeriod.Week) {
          const scaledLabels = findVisibleLabelsXPositionMonthly(
            resp.data.data.pinned_labels || [],
            width,
            currentUniqueMonths,
            selectedTimePeriod
          )
          setVisibleLabels(scaledLabels)
        } else {
          const scaledLabels = findVisibleLabelsXPosition(resp.data.data.pinned_labels || [], currentPositions, width)
          setVisibleLabels(scaledLabels)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [cpi, currentUniqueMonths, selectedTimePeriod, currentPositions, width]
  )

  const fetchCustomLabels = useCallback(
    async (payload: CustomLabelsDataPayload) => {
      try {
        const resp = await axios.post(`/api/v3/labels/cpis/${cpi?.name}?fetch-custom-labels`, payload)

        let filteredData = {} as any
        if (customDate.start && customDate.end) {
          Object.keys(resp.data.data).forEach((item) => {
            if (item >= customDate.start && item <= customDate.end) {
              filteredData[item] = resp.data.data[item]
            }
          })
        } else filteredData = resp.data.data

        setCustomLabels(filteredData)
        setFilteredLabels(filteredData)
      } catch (e) {
        console.error(e)
      }
    },
    [cpi]
  )

  const updateLabels = async (payload?: CustomLabelsDataPayload) => {
    const timePeriod = getPayloadTimePeriod(selectedTimePeriod)
    const thisPayload: any = payload || {
      time_period: timePeriod,
      frequency: selectedFrequency
    }

    if (customTimePeriodValue) {
      const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const parts = customTimePeriodValue.split('-')
      const sub1 = parts[0].split(" '")
      const sub2 = parts[1].split(" '")
      const startMonth = String(shortMonths.findIndex((n) => n === sub1[0]) + 1).padStart(2, '0')
      const startYear = sub1.length > 1 ? `20${sub1[1]}` : `20${sub2[1]}`
      const endMonth = String(shortMonths.findIndex((n) => n === sub2[0]) + 1).padStart(2, '0')
      const endYear = `20${sub2[1]}`
      const daysInEndMonth = dayjs(`${endYear}-${endMonth}-01`).daysInMonth()
      thisPayload.date_from = dayjs(`${startYear}-${startMonth}-01`).format(dateFormat.customLabel)
      thisPayload.date_to = dayjs(`${endYear}-${endMonth}-${daysInEndMonth}`).format(dateFormat.customLabel)
      delete thisPayload.time_period
    }

    try {
      await Promise.all([fetchCustomLabelsCount(thisPayload), fetchCustomLabels(thisPayload)])
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const fetchInitialLabels = useCallback(async () => {
    const payload = {
      frequency: selectedFrequency,
      time_period: getPayloadTimePeriod(selectedTimePeriod)
    }

    setLoading(true)
    try {
      await updateLabels(payload)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [updateLabels, selectedFrequency, selectedTimePeriod])

  const pinLabel = async (labelId: string) => {
    await axios.post(`/api/v3/labels/cpis/pin/${labelId}`)
  }

  const unpinLabel = async (labelId: string) => {
    await axios.delete('api/v3/labels/cpis/pin', { data: { ids: [labelId] } })
  }

  const handlePin = async (labelId: string) => {
    const labelIsPinned = labelsCount?.pinned_labels.find((n) => n.id === labelId)

    try {
      if (!labelIsPinned) {
        await pinLabel(labelId)
      } else {
        await unpinLabel(labelId)
      }

      await updateLabels()
    } catch (err) {
      console.error(err)
    }
  }

  const handleEdit = async (label: Label) => {
    await setEditLabel(label)
    await setLabelDrawerOpen(true)
    await setMode('edit')
  }

  const getCardColors = useCallback((action: SystemEventName) => {
    switch (action) {
      case SystemEventName.CpiInstanceAdded:
        return {
          background: '#402C3F',
          border: `1px solid ${theme.baseColors.primary[60]}`,
          titleColor: theme.baseColors.primary[70],
          icon: <InstanceAddedLabelIcon />
        }
      case SystemEventName.CpiInstanceRemoved:
        return {
          background: '#402C3F',
          border: `1px solid ${theme.baseColors.primary[60]}`,
          titleColor: theme.baseColors.primary[70],
          icon: <InstanceRemovedLabelIcon />
        }
      case SystemEventName.SlaChangedEvent:
        return {
          background: '#45312A',
          border: `1px solid ${theme.baseColors.info[40]}`,
          titleColor: theme.baseColors.info[40],
          icon: <SLAChangeLabelIcon />
        }
      case SystemEventName.CpiActivated:
        return {
          background: '#393144',
          border: '1px solid #8E9AFF',
          titleColor: '#8E9AFF',
          icon: <CPIChangeLabelIcon />
        }
      case SystemEventName.CpiCustom:
      default:
        return {
          background: '#453837',
          border: `1px solid ${theme.baseColors.info[30]}`,
          titleColor: theme.baseColors.info[30],
          icon: <CustomLabelIcon />
        }
    }
  }, [])

  const handleChangeSeverity = async (event: SelectChangeEvent) => {
    if (!manifestData) return

    setRenderingChart(true)
    const value = event.target.value as CPISeverity
    const { user_input: userInput } = manifestData.ui.trend_chart

    let sections: CPIManifestUIVisualizationParamSection[] = []
    if (userInput.visualization_param.sections.length > 1) {
      sections = userInput.visualization_param.sections
    }
    const thisSection = sections.find((n) => n.display_name === value)

    setSelectedSeverity(value)

    const payload: CustomTrendsDataPayload = getPayloadTrendsChart({
      severity: thisSection ? (thisSection.name as CPISeverity) : value,
      selectedDataSourceIds,
      selectedTimePeriod,
      selectedFrequency,
      customTimePeriodValue,
      type: selectedType
    })
    const { stripes } = payload
    if (typeof stripes !== 'undefined') {
      delete payload.stripes
    }

    const response = await refetchPredictionData({
      thisCpiName: manifestData.name || '',
      severity: thisSection ? (thisSection.name as CPISeverity) : value,
      timePeriod: payloadToEnumTimePeriod(payload.timePeriod || '')
    })
    setChartData(response ?? null)
  }

  const renderSeverities = () => {
    if (!manifestData) return null
    const { user_input: userInput } = manifestData.ui.trend_chart

    if (userInput.visualization_param.sections.length <= 1) return null

    if (userInput.formula_params[0]?.sections.length > 0 || userInput.visualization_param.sections.length > 1) {
      let severities: string[] = []
      if (userInput.formula_params[0]?.sections.length > 0) {
        severities = userInput.formula_params[0]?.sections.map((n) => n.display_name as string)
      } else if (userInput.visualization_param.sections.length > 1) {
        severities = userInput.visualization_param.sections.map((n) => n.display_name as string)
      } else {
        return null
      }

      return (
        <>
          <Typography className="chips-title">
            {`${getSingularSectionsName(userInput.visualization_param.sections_display_name)}`}:
          </Typography>
          <TrendsChartSelectField
            options={severities}
            label=""
            placeholder="Filter by Severity"
            labeltext="Filter by: "
            value={selectedSeverity}
            handleChange={handleChangeSeverity}
            disabled={chartData?.status !== DataStatus.Ready || renderingChart}
            minWidth="75px !important"
            hideSelected
          />
        </>
      )
    }

    return null
  }

  return (
    <>
      <ChartWrapper
        status={chartData?.status}
        severity={currentSeverityStatus}
        zone={chartData ? chartData.zoneColor : 'initializing'}
      >
        <TrendsChartDescription
          ref={descriptionWrapperRef as any}
          fetchPredictionData={fetchPredictionData}
          setPredictionData={setPredictionData}
          cpiName={cpi?.name || ''}
          setRedirectToMode={setRedirectToMode}
          status={chartData?.status}
          setSelectedType={setSelectedType}
          zone={chartData ? chartData.zoneColor : 'initializing'}
          isMediumHeight={isMediumHeight}
          manifestData={manifestData}
          chartData={chartData}
          setChartData={setChartData}
          setSelectedTimePeriod={setSelectedTimePeriod}
          setSelectedFrequency={setSelectedFrequency}
          setSelectedDataSourceIds={setSelectedDataSourceIds}
          selectedType={selectedType}
          setDataSourceValue={setDataSourceValue}
          selectedTimePeriod={selectedTimePeriod}
          selectedSeverity={selectedSeverity}
          selectedDataSourceIds={selectedDataSourceIds}
          selectedFrequency={selectedFrequency}
          fetchTrendsData={fetchTrendsData}
          userConfiguredIntegrations={userConfiguredIntegrations}
          cpiConfiguration={cpiConfiguration}
          cpiSupportedAndUserConfiguredList={cpiSupportedAndUserConfiguredList}
          timePeriodValues={timePeriodValues}
          frequencyValues={frequencyValues}
          dataSourceOptions={dataSourceOptions}
          dataSourceValue={dataSourceValue}
          setEmptyInfoTooltipOpen={setEmptyInfoTooltipOpen}
          emptyInfoTooltipOpen={emptyInfoTooltipOpen}
          handleEmptyInfoClick={handleEmptyInfoClick}
          setDrawerOpen={setLabelDrawerOpen}
          setMode={setMode}
          customTimePeriodValue={customTimePeriodValue}
          setCustomTimePeriodValue={setCustomTimePeriodValue}
          renderSeverities={renderSeverities}
          predictionModalOpen={predictionModalOpen}
          handlePredictionModalOpen={handlePredictionModalOpen}
          hasPredictionsEnabled={hasPredictionsEnabled}
          predictionModelAvailable={predictionModelAvailable}
          daysUntilDataAvailable={daysUntilDataAvailable}
          renderingChart={renderingChart}
          setRenderingChart={setRenderingChart}
        />
        {descriptionWrapperRef && (
          <Box className="chart-container">
            <div ref={containerRef} className="trends-d3-wrapper">
              <TrendsChart
                ref={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  containerRef,
                  svgRef
                }}
                visibleLabels={visibleLabels}
                setWidth={setWidth}
                setHeight={setHeight}
                width={width}
                height={height}
                margin={margin}
                healthValue={(defaultValues.sla as string) || ''}
                currentSeverityStatus={currentSeverityStatus}
                handlePin={handlePin}
                handleEdit={handleEdit}
                getCardColors={getCardColors}
                chartStatus={chartData?.status}
                renderingChart={renderingChart}
              />
            </div>
          </Box>
        )}
      </ChartWrapper>
      <Typography className="cpi-short-description">{manifestData.short_description}</Typography>

      <LabelsDrawer
        chartDateRange={chartDateRange}
        redirectToMode={redirectToMode}
        editedLabel={editLabel}
        loading={loading}
        appliedFilter={appliedFilter}
        setLoading={setLoading}
        drawerOpen={labelDrawerOpen}
        customDate={customDate}
        cpiName={cpi?.name}
        cpiConfId={cpi?.configuration?.id}
        setDrawerOpen={setLabelDrawerOpen}
        setMode={setMode}
        mode={mode}
        updateLabels={updateLabels}
        filterLabelsData={filterLabelsData}
        selectedTimePeriod={selectedTimePeriod}
        selectedFrequency={selectedFrequency}
        customLabels={filteredLabels}
        customLabelsCount={customLabelsCount}
        unpinLabel={unpinLabel}
        pinLabel={pinLabel}
        visibleLabels={visibleLabels}
        setVisibleLabels={setVisibleLabels}
      />
    </>
  )
}

export default TrendsTab
