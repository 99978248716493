import theme from 'theme'

const SecurityStackMapIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.35714 8.125L1.875 10L5.35714 11.875M5.35714 8.125L10 10.625L14.6429 8.125M5.35714 8.125L1.875 6.25L10 1.875L18.125 6.25L14.6429 8.125M14.6429 8.125L18.125 10L14.6429 11.875M14.6429 11.875L18.125 13.75L10 18.125L1.875 13.75L5.35714 11.875M14.6429 11.875L10 14.375L5.35714 11.875"
      stroke={theme.baseColors.greyscale[80]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SecurityStackMapIcon
