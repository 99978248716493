import theme from 'theme'

const SidebarSettingsActiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.23152 1.875C8.46771 1.875 7.81585 2.42721 7.69028 3.18063L7.54139 4.07394C7.5246 4.1747 7.44612 4.2915 7.2942 4.36456C7.00748 4.50244 6.733 4.6615 6.47277 4.83967C6.33403 4.93466 6.19376 4.94433 6.09801 4.90846L5.2487 4.59028C4.53343 4.32231 3.72927 4.61074 3.34737 5.27222L2.57905 6.60298C2.19715 7.26446 2.34944 8.10509 2.93914 8.59055L3.63957 9.16717C3.71843 9.23209 3.78018 9.35819 3.7676 9.5256C3.75582 9.6823 3.74984 9.84051 3.74984 10C3.74984 10.1595 3.75583 10.3178 3.76761 10.4746C3.7802 10.642 3.71845 10.7681 3.63959 10.833L2.93914 11.4097C2.34944 11.8951 2.19715 12.7357 2.57905 13.3972L3.34737 14.728C3.72927 15.3895 4.53343 15.6779 5.2487 15.4099L6.0982 15.0917C6.19395 15.0558 6.33421 15.0655 6.47295 15.1605C6.73312 15.3386 7.00755 15.4976 7.2942 15.6354C7.44612 15.7085 7.5246 15.8253 7.54139 15.9261L7.69028 16.8194C7.81585 17.5728 8.46771 18.125 9.23152 18.125H10.7682C11.532 18.125 12.1838 17.5728 12.3094 16.8194L12.4583 15.9261C12.4751 15.8253 12.5536 15.7085 12.7055 15.6354C12.9922 15.4976 13.2667 15.3385 13.5269 15.1603C13.6656 15.0653 13.8059 15.0557 13.9017 15.0915L14.751 15.4097C15.4662 15.6777 16.2704 15.3893 16.6523 14.7278L17.4206 13.397C17.8025 12.7355 17.6502 11.8949 17.0605 11.4094L16.3601 10.8328C16.2812 10.7679 16.2195 10.6418 16.2321 10.4744C16.2438 10.3177 16.2498 10.1595 16.2498 10C16.2498 9.84045 16.2438 9.68218 16.2321 9.52543C16.2195 9.358 16.2812 9.2319 16.3601 9.16698L17.0605 8.59035C17.6502 8.10489 17.8025 7.26426 17.4206 6.60278L16.6523 5.27202C16.2704 4.61054 15.4662 4.32211 14.751 4.59008L13.9015 4.90833C13.8057 4.9442 13.6655 4.93453 13.5267 4.83955C13.2666 4.66142 12.9921 4.5024 12.7055 4.36456C12.5536 4.2915 12.4751 4.1747 12.4583 4.07394L12.3094 3.18063C12.1838 2.42721 11.532 1.875 10.7682 1.875H9.23152ZM9.99994 13.125C11.7258 13.125 13.1249 11.7259 13.1249 10C13.1249 8.27411 11.7258 6.875 9.99994 6.875C8.27405 6.875 6.87494 8.27411 6.87494 10C6.87494 11.7259 8.27405 13.125 9.99994 13.125Z"
      fill={theme.colors.white}
    />
  </svg>
)

export default SidebarSettingsActiveIcon
