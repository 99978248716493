import { FC } from 'react'
import { Box, Typography } from '@mui/material'

/* Utils */
import { PerformanceScoreCategoryDetailsDataSources, CategoryActivityItem } from '../../../../../../models'

/* Components */
import { CategoryWrapper, EmptyDataCategoryWrapper } from './category.styles'
import DataSources from './DataSources'

interface Props {
  category: CategoryActivityItem
  handleCategoryClick: (category: string) => void
  dataSources: PerformanceScoreCategoryDetailsDataSources[]
  ishovered?: string
  minWidth?: string
}

const EmptyDataCategory: FC<Props> = ({ category, handleCategoryClick, dataSources, ishovered, minWidth }) => (
  <CategoryWrapper
    className="empty-data-category-wrapper"
    titlelength={category.name.length}
    ishovered={ishovered}
    sx={{ minWidth }}
  >
    <Box className="header" onClick={() => handleCategoryClick(category.name)}>
      <Typography className="category-title category-margin">{category.name}</Typography>
    </Box>

    <EmptyDataCategoryWrapper>
      <Box className="scale">
        <Typography className="scale-text">100%</Typography>
        <Typography className="scale-text">50%</Typography>
        <Typography className="scale-text">0%</Typography>
      </Box>
      <Box className="chart-button-wrapper">
        <Typography className="no-data-text">No data found</Typography>
      </Box>
    </EmptyDataCategoryWrapper>
    <DataSources dataSources={dataSources} />
  </CategoryWrapper>
)

export default EmptyDataCategory
