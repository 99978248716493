import theme from 'theme'

const SidebarReportsActiveIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6875 1.25H7.5C9.22589 1.25 10.625 2.64911 10.625 4.375V5.9375C10.625 6.80044 11.3246 7.5 12.1875 7.5H13.75C15.4759 7.5 16.875 8.89911 16.875 10.625V17.1875C16.875 18.0504 16.1754 18.75 15.3125 18.75H4.6875C3.82455 18.75 3.125 18.0504 3.125 17.1875V2.8125C3.125 1.94955 3.82456 1.25 4.6875 1.25ZM10.4419 9.55806C10.3247 9.44085 10.1658 9.375 10 9.375C9.83424 9.375 9.67527 9.44085 9.55806 9.55806L7.05806 12.0581C6.81398 12.3021 6.81398 12.6979 7.05806 12.9419C7.30214 13.186 7.69786 13.186 7.94194 12.9419L9.375 11.5089L9.375 15C9.375 15.3452 9.65482 15.625 10 15.625C10.3452 15.625 10.625 15.3452 10.625 15L10.625 11.5089L12.0581 12.9419C12.3021 13.186 12.6979 13.186 12.9419 12.9419C13.186 12.6979 13.186 12.3021 12.9419 12.0581L10.4419 9.55806Z"
      fill={theme.colors.white}
    />
    <path
      d="M11.875 4.375C11.875 3.28079 11.4733 2.2804 10.8093 1.51325C13.6418 2.25315 15.8719 4.48323 16.6117 7.31567C15.8446 6.6517 14.8442 6.25 13.75 6.25H12.1875C12.0149 6.25 11.875 6.11009 11.875 5.9375V4.375Z"
      fill={theme.colors.white}
    />
  </svg>
)

export default SidebarReportsActiveIcon
